import { useState, useMemo } from "react";
import { cn } from "../../../../util/cn";
import SubscriptionCard from "../../details/New_Ui/Component/CurrentPlan/SubscriptionCard";
import DialogWrapper from "../../../custom-ui/dialog/Dialog_NewUI";
import SpeakWithAccountExecutiveModal from "../../details/New_Ui/Component/CurrentPlan/SpeakWithAccountExecutiveModal";
import SpeakWithAccountExecutiveThankYouModal from "../../details/New_Ui/Component/CurrentPlan/SpeakWithAccountExecutiveThankYouModal";

type BuySubscriptionProps = {
  currentSubscription?: string;
  isSignup?: boolean;
  onContinue: any;
};

const BuySubscription = ({
  currentSubscription = "",
  isSignup = true,
  onContinue,
}: BuySubscriptionProps) => {
  // const [selectedSubscription, setSelectedSubcription] = useState("");
  const [
    isAnnualSpeakWithAccountExecutiveModalVisible,
    setIsAnnualAccountExecutiveLeads,
  ] = useState(false);
  const [
    isAnnualSpeakWithAccountExecutiveThankYouModalVisible,
    setIsAnnualSpeakWithAccountExecutiveThankYouModalVisible,
  ] = useState(false);

  const filteredSubscriptions = useMemo(() => {
    if (isSignup) {
      return subscriptions;
    }
    return subscriptions.filter((subscription) => {
      return subscription.type !== currentSubscription;
    });
  }, [currentSubscription, isSignup]);

  function handleSelection(selectedSubscription = "") {
    if (selectedSubscription) {
      if (selectedSubscription === "annual") {
        setIsAnnualAccountExecutiveLeads(true);
      } else {
        onContinue(selectedSubscription);
      }
    }
  }

  const closeAnnualSpeakWithAccountExecutiveModal = () => {
    setIsAnnualAccountExecutiveLeads(false);
  };

  const onCancelAnnualSpeakWithAccountExecutiveModal = () => {
    setIsAnnualAccountExecutiveLeads(false);
  };

  const onSubmitAnnualSpeakWithAccountExecutiveModal = () => {
    setIsAnnualAccountExecutiveLeads(false);
    setIsAnnualSpeakWithAccountExecutiveThankYouModalVisible(true);
  };

  const closeAnnualSpeakWithAccountExecutiveThankYouModal = () => {
    setIsAnnualSpeakWithAccountExecutiveThankYouModalVisible(false);
  };

  const onSubmitAnnualSpeakWithAccountExecutiveThankYouModal = () => {
    setIsAnnualSpeakWithAccountExecutiveThankYouModalVisible(false);
    window.location.href = "https://instantteams.com/";
  };

  return (
    <>
      <div
        className={cn("flex flex-col items-center overflow-hidden", {
          "px-10": isSignup,
        })}
      >
        <div
          className={cn("flex flex-row gap-[30px] overflow-x-auto w-full", {
            "justify-start pb-5": isSignup,
            "justify-center": !isSignup,
          })}
        >
          {filteredSubscriptions.map((subscription: any) => {
            return (
              <SubscriptionCard
                type={subscription.type}
                title={subscription.title}
                subTitle={subscription.subTitle}
                price={subscription.price}
                // isSelected={selectedSubscription === subscription.type}
                isSelected={false}
                // onClick={() => setSelectedSubcription(subscription.type)}
                onContinue={() => handleSelection(subscription.type)}
              />
            );
          })}
        </div>

        {/* <button
          onClick={handleSelection}
          className={cn(
            "mt-[40px] w-[260px] h-[60px] rounded-[70px] py-[20px] px-[70px] flex flex-row justify-center items-center text-[#FFFFFF]",
            {
              "bg-[#8AA0AE]": !selectedSubscription,
              "bg-[#15415E]": selectedSubscription,
            },
          )}
          disabled={!selectedSubscription}
        >
          Continue
        </button> */}
      </div>

      {isAnnualSpeakWithAccountExecutiveModalVisible && (
        <DialogWrapper
          title="Get In Touch"
          onClose={closeAnnualSpeakWithAccountExecutiveModal}
          displayCloseIcon={true}
          displayHeaderHorizontalLine={true}
          size="max-w-[630px]"
        >
          <SpeakWithAccountExecutiveModal
            onCancel={onCancelAnnualSpeakWithAccountExecutiveModal}
            onDone={onSubmitAnnualSpeakWithAccountExecutiveModal}
            type="request"
            source="annualSubscriptionSignup"
          />
        </DialogWrapper>
      )}

      {isAnnualSpeakWithAccountExecutiveThankYouModalVisible && (
        <DialogWrapper
          title=""
          onClose={closeAnnualSpeakWithAccountExecutiveThankYouModal}
          displayCloseIcon={true}
          displayHeaderHorizontalLine={true}
          size="max-w-[680px]"
        >
          <SpeakWithAccountExecutiveThankYouModal
            onDone={onSubmitAnnualSpeakWithAccountExecutiveThankYouModal}
          />
        </DialogWrapper>
      )}
    </>
  );
};

export default BuySubscription;

const subscriptions = [
  {
    type: "single",
    title: "Single",
    subTitle: "Fuel Your Talent Pipeline with Ease",
    price: "$299",
  },
  {
    type: "monthly",
    title: "Monthly",
    subTitle: "Empower Your Hiring Strategy, Month After Month",
    price: "$599",
  },
  {
    type: "annual",
    title: "Annual",
    subTitle: "Your Gateway to Strategic Hiring Success",
    price: "",
  },
];
