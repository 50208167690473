import React from 'react';
import { getEmployerAdminSubscriptionLandingPageData } from '../../../../../callable-cloud-functions/cloudFunctions';
import { useParams } from 'react-router-dom';
import { convertTimestamp_subAdmin } from '../util';
import AboutSubCurrentPlan_EmpProfile from '../Component/EmpSubcriptionDetail_Admin/AboutSubCurrentPlan_EmpProfile';
import PaymentInformationEmpProfile from '../Component/EmpSubcriptionDetail_Admin/PaymentInformation_EmpProfile';
import CreditsInformationEmpProfile from '../Component/EmpSubcriptionDetail_Admin/CreditsInformation_EmpProfile';
import AddAnnualSubcriptionForEmp from '../Component/EmpSubcriptionDetail_Admin/AddAnnualSubcriptionForEmp';
import { useEffect } from 'react';
import { useState } from 'react';
import LoadingSpinner from '../../../../dashboard/AdminMetricDashboard/LoadingIndicator/LoadingSpinner';
import { useEmployer } from '../../../../../data/employer';
const SubcriptionsNewUIAdmin = () => {

  const { employerId } = useParams();
  const [subcriptionData, setSubcriptionData] = useState();
  const [loading, setloading] = useState(false);

  const { data: employer } = useEmployer(employerId || "")

  useEffect(() => {
    const fetchSubcriptionData = async () => {
      setloading(true)
      const data = await getEmployerAdminSubscriptionLandingPageData({
        employerId: employerId
      })
      if (data) {
        setSubcriptionData(data?.data)
        setloading(false)
      }

      return;
    }
    fetchSubcriptionData();
  }, [])


  setTimeout(() => {
    setloading(false)
  }, 7000)

  const { date, mAndY } = convertTimestamp_subAdmin(subcriptionData?.subscriptionExpiry) || {};
  return (
    <>
     {loading&& <div className='w-[70vw] h-[80vh] flex justify-center items-center'>
      <img className='w-24 h-24' src="/images/loader_v2.gif " alt="loader" />
      </div>}
      <div className='w-full space-y-3 h-full mr-24'>
        <div>
          {subcriptionData?.currentPlan && <h1 className='font-bold text-instant-teams-blue-Main text-xl '>
            {subcriptionData?.currentPlan === "monthly" ? "Monthly Plan Subscription with 3 credits" :
              subcriptionData?.currentPlan === "single" ? "Single Plan Subscription with 1 credit" : "Annual Plan Subscription"
            }
          </h1>}
        </div>
        {(!subcriptionData?.currentPlan ||
          (subcriptionData.currentPlan === "single" &&
            subcriptionData?.credits?.length === 0)) &&
          !employer?.tac_managed && (
          <AddAnnualSubcriptionForEmp empId={employerId} />
        )}

        {
          subcriptionData?.currentPlan || subcriptionData?.credits?.length > 0 ? (<>
            <div className='grid grid-cols-2 mr-7 space-x-3'>
              <div className={`bg-[#ffffff] p-4 w-full   shadow-lg rounded-lg relative`}>
                {subcriptionData?.currentPlan ? <AboutSubCurrentPlan_EmpProfile subcriptionData={subcriptionData} empId={employerId} /> : (
                  <div className='w-full h-full flex justify-center items-center'>
                    <h1 className='text-xl text-instant-teams-blue-Main '> Currently No Active Plan</h1>
                  </div>

                )}
              </div>

              <div className='grid grid-cols-1 space-x-3 '>
                <div className={`bg-[#ffffff] flex flex-col justify-center items-center p-3 w-full h-full  shadow-lg rounded-lg relative`}>
                  <h1 className='text-instant-teams-blue-Main  font-thin'>
                    AVAILABLE CREDITS
                  </h1>
                  <h1 className=' mt-1 text-instant-teams-blue-Main font-extrabold text-[75px]'>
                    {subcriptionData?.availableCredits ? subcriptionData?.availableCredits : "0"}
                  </h1>
                </div>
                {/* <div className={`bg-[#ffffff] flex flex-col justify-center items-center  p-3 w-full h-full  shadow-lg rounded-lg relative`}>
                <h1 className='text-instant-teams-blue-Main  font-thin'>
                  SUBCRIPTION EXPIRY
                </h1>
                <h1 className='  text-instant-teams-blue-Main font-extrabold text-[65px]'>
                  {date}
                </h1>
                <h1 className=' text-instant-teams-blue-Main font-extrabold text-[25px]'>
                  {mAndY}
                </h1>
              </div> */}

              </div>
            </div>
            <div className='grid grid-cols-2 mr-7 space-x-3'>
              <div className={`bg-[#ffffff] p-4 w-full   shadow-lg rounded-lg relative`}>
                <PaymentInformationEmpProfile subcriptionData={subcriptionData} />
              </div>
              <div className={`bg-[#ffffff] p-4 w-full   shadow-lg rounded-lg relative`}>
                <CreditsInformationEmpProfile subcriptionData={subcriptionData} empId={employerId} />
              </div>
            </div>

          </>) : <div className='w-full h-[60vh] flex flex-col justify-center items-center'>

            <img className='w-[30vw] h-[35vh]' src="/images/admin-subcriotion-placeholder.png"></img>
            <p className='text-base text-instant-teams-blue-Main mt-4 font-bold'>No Subcription Data</p>
          </div>
        }


      </div>
    </>
  );
}

export default SubcriptionsNewUIAdmin;
