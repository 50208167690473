import React, { useState } from 'react';
import useModal from '../../../../../../hooks/useModal';
import DialogWrapper from '../../../../../custom-ui/dialog/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-duotone-svg-icons';
import AnnualSubcriptionCard from './AnnualSubcriptionCard';
import ButtonWrapper from '../../Component-UI/Button';
import toast from 'react-hot-toast';
import ToastAlert from '../../../../../custom-ui/toast-alert/ToastAlert';
import AddAnnualSubcriptionForm from './AddAnnualSubForm';

const AddAnnualSubcriptionForEmp = ({ empId }: any) => {
    const annualPlan = [
        { noOfjobs: 5, planAmt: 3499 },
        { noOfjobs: 10, planAmt: 5999 },
        { noOfjobs: 20, planAmt: 9999 }
    ];

    const { isShowing: showSubcriptionPlanModal, toggle: toggleShowSubcriptionPlanModal } = useModal();
    const { isShowing: showSubcriptionFormModal, toggle: toggleShowSubcriptionFormModal } = useModal();
    const [selectedPlan, setSelectedPlan] = useState(null); // Tracks the selected plan's `noOfjobs`

    const handleCardSelection = (noOfJobs: any) => {
        setSelectedPlan(noOfJobs);
    };

    const handleContinuePlan = () => {
        if (selectedPlan) {
            toggleShowSubcriptionPlanModal()
            toggleShowSubcriptionFormModal()
        } else {
            toast.error("Please select any of these above annual plan")
        }
    }
    return (
        <>
            <div className="flex justify-end mr-7">
                <button
                    className="bg-instant-teams-blue-Main text-white px-8 py-1"
                    onClick={toggleShowSubcriptionPlanModal}
                >
                    Add Plan
                </button>
            </div>
            {showSubcriptionPlanModal && (
                <div className="fixed w-full inset-0 xs:p-4 md:p-4 flex items-center justify-center z-50">
                    <div className="fixed  w-full inset-0 bg-black opacity-50" onClick={toggleShowSubcriptionPlanModal}></div>
                    <div
                        className={`relative transform transition-transform  duration-300 xs:w-full md:w-full ease-in-out bg-white rounded-2xl w-auto shadow-lg p-8`}

                    >
                        <div className='w-full'>
                            <div className="w-[100%] xs:mt-2  py-3 md:mt-2 flex justify-between items-start">
                                <h1 className="text-instant-teams-blue-Main text-xl text-center font-semibold">
                                    Add Plan
                                </h1>
                                <div
                                    className="flex justify-center items-center w-7 h-7 bg-gray-200 rounded-full cursor-pointer"
                                    onClick={toggleShowSubcriptionPlanModal}
                                >
                                    <FontAwesomeIcon icon={faTimes} color="#565656" />
                                </div>
                            </div>

                            <div className="w-full mt-5 flex space-x-3">
                                {annualPlan.map((data, index) => (
                                    <AnnualSubcriptionCard
                                        key={index}
                                        isSelected={selectedPlan === data.noOfjobs}
                                        setSelected={handleCardSelection}
                                        noOfJobs={data.noOfjobs}
                                        planAmt={data.planAmt}
                                    />
                                ))}
                            </div>
                            <div className=' mt-10 flex justify-center '>
                                <button className=' h-[50px] w-[210px] rounded-[70px] bg-[#15415E] px-5 py-3 hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]'
                                    onClick={handleContinuePlan}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            )}

            {
                showSubcriptionFormModal && (
                    <div className="fixed w-full inset-0 xs:p-4 md:p-4 flex items-center justify-center z-50">
                        <div className="fixed inset-0 bg-black opacity-50" onClick={toggleShowSubcriptionFormModal}></div>
                        <div
                            className={`relative transform  transition-transform  duration-300 xs:w-full md:w-full ease-in-out bg-white rounded-2xl w-[22vw] shadow-lg p-8`}

                        >
                            <div className="w-full xs:mt-2 md:mt-2 flex  justify-between ">
                                <h1 className="text-instant-teams-blue-Main text-xl text-center font-semibold">
                                    Add Plan
                                </h1>
                                <div
                                    className="flex justify-center items-center w-7 h-7 bg-gray-200 rounded-full cursor-pointer"
                                    onClick={toggleShowSubcriptionFormModal}
                                >
                                    <FontAwesomeIcon icon={faTimes} color="#565656" />
                                </div>
                            </div>

                            <AddAnnualSubcriptionForm empId={empId} toggleShowSubcriptionFormModal={
                                toggleShowSubcriptionFormModal
                            } selectedPlan={selectedPlan} />
                        </div>
                    </div>


                )
            }

            <ToastAlert />
        </>
    );
};

export default AddAnnualSubcriptionForEmp;
