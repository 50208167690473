import { cn } from "../../../../../../util/cn";
import BulletTextWrapper from "./BulletTextWrapper";

type SubscriptionCardProps = {
  type: string;
  title: string;
  subTitle: string;
  price: string;
  isSelected: boolean;
  // onClick: () => void;
  onContinue: () => void;
};

const SubscriptionCard = ({
  type,
  title,
  subTitle,
  price,
  isSelected,
  // onClick,
  onContinue,
}: SubscriptionCardProps) => {
  return (
    <div
      className={cn(
        "flex flex-col w-[280px] min-h-[510px] pt-[35px] pb-[30px] border-[1px] border-[rgba(138, 160, 174, 0.5)] rounded-[20px] items-center ",
        {
          "bg-gradient-to-r from-[#0B619A] to-[#4DAAE8]": isSelected,
        },
      )}
      // onClick={onClick}
    >
      <p
        className={cn(
          "font-[Gilroy-Bold] font-[400] text-[24px] leading-[29.71px] ",
          {
            "text-[#15415E]": !isSelected,
            "text-[#FFFFFF]": isSelected,
          },
        )}
      >
        {title}
      </p>
      <p
        className={cn(
          "mt-[10px] font-[Gilroy-Medium] font-[400] text-center text-[16px] leading-[19.41px]",
          {
            "text-[#15415E]": !isSelected,
            "text-[#FFFFFF]": isSelected,
          },
        )}
      >
        {subTitle}
        {/* {subTitle ? subTitle : <>&nbsp;</>} */}
      </p>
      <div
        className={cn(
          "mt-[30px] mx-[30px] flex-1 flex flex-col gap-[15px] font-[Gilroy-Regular] font-[400] text-[12px] leading-[14.4px]",
          {
            "text-[#8AA0AE]": !isSelected,
            "text-[#FFFFFF]": isSelected,
          },
        )}
      >
        {type === "single" && (
          <>
            <BulletTextWrapper>Includes one 30-day job post</BulletTextWrapper>
            <BulletTextWrapper>Utilize anytime within a year</BulletTextWrapper>
            <BulletTextWrapper>
              Features employer profile and logo
            </BulletTextWrapper>
            <BulletTextWrapper>
              Funnels candidates directly to your ATS
            </BulletTextWrapper>
          </>
        )}
        {type === "monthly" && (
          <>
            <BulletTextWrapper>
              Includes three (3) 30-day job post credits each month
            </BulletTextWrapper>
            <BulletTextWrapper>
              Credits refresh monthly to maintain active participation
            </BulletTextWrapper>
            <BulletTextWrapper>
              Features employer profile and logo
            </BulletTextWrapper>
            <BulletTextWrapper>
              Funnels candidates directly to your ATS
            </BulletTextWrapper>
            <BulletTextWrapper>
              Automatic renewal and billing, cancel anytime
            </BulletTextWrapper>
          </>
        )}
        {type === "annual" && (
          <>
            <BulletTextWrapper>Customizable Packages</BulletTextWrapper>
            <BulletTextWrapper>Seamless Renewals</BulletTextWrapper>
            <BulletTextWrapper>
              Expert Partnership & Sales Support
            </BulletTextWrapper>
          </>
        )}
      </div>

      {/* <button
        className={cn(
          "mt-[30px] relative font-[Gilroy-Bold] font-[400] text-[16px] leading-[19.81px] ",
          {
            "bg-gradient-to-r from-[#0B619A] to-[#4DAAE8] bg-clip-text text-transparent after:content-[''] after:absolute after:left-0 after:right-0 after:bottom-[-2px] after:h-[2px] after:bg-gradient-to-r after:from-[#0B619A] after:to-[#4DAAE8]":
              !isSelected,
            "bg-[#FFFFFF] bg-clip-text text-transparent after:content-[''] after:absolute after:left-0 after:right-0 after:bottom-[-2px] after:h-[2px] after:bg-[#FFFFFF]":
              isSelected,
          },
        )}
      >
        See All Benefits
      </button> */}

      {type === "annual" ? (
        <button
          className="mt-[40px] mx-[20px] h-[46px] w-[210px] rounded-[70px] px-[35px] py-[15px] bg-[#15415E] text-[#FFFFFF] font-[Gilroy-Bold] font-[400] text-[14px] leading-[17.33px] flex flex-row justify-center items-center z-[999]"
          onClick={() => {
            onContinue();
          }}
        >
          Speak with an Account Executive
        </button>
      ) : (
        <>
          <p
            className={cn(
              "flex flex-row justify-center mt-[35px] w-[250px] font-[Gilroy-Bold] font-[400] text-[40px] leading-[49.52px]",
              {
                "text-[#15415E]": !isSelected,
                "text-[#FFFFFF]": isSelected,
              },
            )}
          >
            {price}
          </p>
          <button
            className="mt-[40px] mx-[20px] h-[46px] w-[210px] rounded-[70px] px-[35px] py-[15px] bg-[#15415E] text-[#FFFFFF] font-[Gilroy-Bold] font-[400] text-[14px] leading-[17.33px] flex flex-row justify-center items-center"
            onClick={onContinue}
          >
            Continue
          </button>
        </>
      )}

      <p
        className={cn(
          "mt-[40px] mx-[21px] font-[Gilroy-Regular] font-[400] text-[10px] leading-[12px] text-center",
          {
            "text-[#15415E80]": !isSelected,
            "text-[#FFFFFF]": isSelected,
          },
        )}
      >
        &nbsp;
        {/* All packages include direct ATS apply, IT Marketplace badge for company
        website and analytics */}
      </p>
    </div>
  );
};

export default SubscriptionCard;
