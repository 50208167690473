import { useState, useEffect, Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useLookup } from "../../../../../../data/lookup";
import {
    getIsSubmittingJobFirstTime,
    updateEntryForJobDetailsNewCollection,
    createNewEntryForJobDetailsNewCollection,
    useJobDetailsNewCollection
} from "../../../../../../data/jobDetailsNewCollection";

import GreenUnderlineHeading from "../../../../../custom-ui/headings/heading";
import { isLockedJobNew } from "../../../../../../util/jobs/jobUtils";
import Input from "../../../../../custom-ui/old-ui/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { JobFormSchema5 } from "./JobFormSchema5";
import Wysiwyg from "../../../../../custom-ui/old-ui/Wysiwyg";
import SelectGeneric from "../../../../../custom-ui/old-ui/SelectGeneric";
import DateGeneric from "../../../../../custom-ui/old-ui/DateGeneric";
import { JobDetailsNewCollection } from "../../../../../../dataTypes/JobDetailsNewCollection";
import RadioGroup from "../../../../../custom-ui/old-ui/RadioGroup";
import toast from "react-hot-toast";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import Checkbox from "../../../../../custom-ui/old-ui/Checkbox";
import InputCurrency from "../../../../../custom-ui/old-ui/InputCurrency";
import TextArea from "../../../../../custom-ui/old-ui/TextArea";
import { useMemo } from "react";
import Tooltip from "../../../../../custom-ui/tooltip/tooltip";
import SliderWithCheckbox from "../../../../../custom-ui/old-ui/SliderWithCheckbox";
import { useAuth } from "../../../../../../providers/auth";
import { useEmployer } from "../../../../../../data/employer";
import { useNavigate } from "react-router-dom";
import InfoIcon from "../../../../../dashboard/AdminMetricDashboard/InfoTextWithIcon";

function JobInformationDescForm({ currStep, setCurrentStep, setFormvalues, formvalues }: any) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const employerId = user?.claims?.employerId;
    const { data: employer } = useEmployer(employerId ?? "");
    const params = useParams();
    const jobId = params.id ?? "";

    const [isInitialized, setIsInitialized] = useState(false);
    const [formSchema, setFormSchema] = useState(JobFormSchema5(false));

    const { data: job } = useJobDetailsNewCollection(jobId);

    const { data: aspectsOfJob = [] } = useLookup("aspectsOfJob");
    const { data: aboutThisJob = [] } = useLookup("aboutThisJob");
    const { data: mustHaves = [] } = useLookup("mustHaves");

    const aboutThisJobOptions = useMemo(() => {
        return aboutThisJob.map((obj: any) => {
            const labelSplitArray = obj.label.split("<--->");

            const labelLeft = labelSplitArray[0];
            const labelRight = labelSplitArray[1];

            return {
                ...obj,
                labelLeft,
                labelRight,
            };
        });
    }, [aboutThisJob]);

    const isFieldLocked = false;

    const lockedJobStatusToolTipText = useMemo(() => {
        let jobState = job?.jobState;

        if (["closed_filled", "closed_cancelled"].includes(jobState)) {
            jobState = "closed";
        } else if (jobState === "on_hold") {
            jobState = "on hold";
        } else {
            jobState = "live";
        }
        return jobState;
    }, [job]);

    const SECTION_LOCKED_MESSAGE = `This section cannot be changed while the job status is ${lockedJobStatusToolTipText}.`;

    type FormValues = z.infer<ReturnType<typeof JobFormSchema5>>;

    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {


            jobSummary: "",

            whatsInItForYou: "",
            dayInLife: "",
            mustHaves: "",

            aspectsOfJob: [],
            isAspectsOfJobOther: false,
            aspectsOfJobOther: "",

            aboutThisJob: [],

            mustHavesCheckboxes: [],
        },
        reValidateMode: "onBlur",
    });

    const isSubmittingJobFirstTime = true

    const {
        handleSubmit,
        setValue,
        getValues,
        control,
        reset,
        watch,
        formState: { errors, isSubmitting },
    } = form;

    // Watch for the value of payRateType

    // Watch for the value of aspectsOfJob
    const aspectsOfJobWatch = watch("aspectsOfJob");
    // Watch for the value of isAspectsOfJobOther
    const isAspectsOfJobOther = watch("isAspectsOfJobOther");
    // Watch for the value of mustHavesCheckboxes
    const mustHavesCheckboxesWatch = watch("mustHavesCheckboxes");
    // Watch for the value of mustHavesCheckboxes
    const aboutThisJobWatch = watch("aboutThisJob");
    // Watch for the value of jobSummary
    const jobSummaryWatch = watch("jobSummary");
    // Watch for the value of aspectsOfJobOther
    const aspectsOfJobOtherWatch = watch("aspectsOfJobOther");

    console.log(formvalues.openings,"opnenings")

    const onSubmit = async ({ ...values }: FormValues) => {


        // eVPLb8f5rLpwhJPgLz7w
        const {
            jobSummary,
            whatsInItForYou,
            dayInLife,
            mustHaves,

            aspectsOfJob = [],
            aspectsOfJobOther = "",
            isAspectsOfJobOther,

            aboutThisJob = [],

            mustHavesCheckboxes = [],

            ...restValues
        } = values;

        const jobDetailsNewCollectionData2: any = {
            ...restValues,
            jobName: formvalues?.jobName,
            companyId: employer?.id,
            creationDate: "",
            startDateInclude:formvalues?.startDateInclude,
            jobState: "draft",
            postedBy: {
                userName: user?.displayName,
                userId: user?.id
            },
            applicationFlow: formvalues.applicationFlow,
            applyNowCounter: 0,
            clicksCounter: 0,
            closedFilledBy: "",
            closedReason: "",
            companyFullName: employer?.displayName,
            startDate: formvalues.startDate || "",
            workSettings: formvalues?.workSettings,
            workTerm: formvalues?.workTerm,
            workType: formvalues?.workType,
            solutionType: "job_board",
            companyLogo: employer?.logo||"/images/default-employer.jpg",
            companyHQ: employer?.address?.standardizedAddress||employer?.location,
            hoursPerWeek: {
                min: Number(formvalues?.hoursPerWeekMin),
                max: Number(formvalues?.hoursPerWeekMax),
            },
            instantTeamJobs: null,
            jobDescription: {
                // jobSummary: jobSummary || "",
                whatsInItForYou:
                    isSubmittingJobFirstTime ||
                        job?.jobDescription?.whatsInItForYou === null
                        ? null
                        : whatsInItForYou || "",
                dayInLife: dayInLife || jobSummary || "",
                // dayInLife:
                //   isSubmittingJobFirstTime ||
                //   aboutThisJob.length ||
                //   job?.jobDescription?.dayInLife === null
                //     ? null
                //     : dayInLife || "",
                mustHaves:
                    isSubmittingJobFirstTime ||
                        mustHavesCheckboxes.length ||
                        job?.jobDescription?.mustHaves === null
                        ? null
                        : mustHaves || "",

                aspectsOfJob,
                aspectsOfJobOther: isAspectsOfJobOther ? aspectsOfJobOther : "",
                aboutThisJob,
                mustHavesCheckboxes,
            },
            makeLiveDate: null,
            openings: Number(formvalues.openings||1),
            payRate: {
                type: formvalues?.payRateType,
                ...(["salary", "hourly", "commission"].includes(formvalues?.payRateType) &&
                    formvalues?.commission !== "" &&
                    formvalues?.commission !== undefined
                    ? {
                        commission: formvalues?.commission,
                        additionalBenifits: formvalues?.additionalBenifits||""
                    }
                    : {
                        commission: null,
                    }),
                ...(formvalues?.payRateType === "unpaid_internship" &&
                    formvalues?.unpaidInternship !== "" &&
                    formvalues?.unpaidInternship !== undefined
                    ? {
                        unpaidInternship: formvalues?.unpaidInternship,
                    }
                    : {
                        unpaidInternship: null,
                    }),
                ...(formvalues?.payRateType === "salary" &&
                    formvalues?.salaryMin !== "" &&
                    formvalues?.salaryMax !== "" &&
                    formvalues?.salaryMin !== undefined &&
                    formvalues?.salaryMax !== undefined
                    ? {
                        salary: {
                            min: Number(formvalues?.salaryMin),
                            max: Number(formvalues?.salaryMax),
                        },
                    }
                    : {
                        salary: null,
                    }),
                ...(formvalues?.payRateType === "hourly" &&
                    formvalues?.hourlyMin !== "" &&
                    formvalues?.hourlyMax !== "" &&
                    formvalues?.hourlyMin !== undefined &&
                    formvalues?.hourlyMax !== undefined
                    ? {
                        hourly: {
                            min: Number(formvalues?.hourlyMin),
                            max: Number(formvalues?.hourlyMax),
                        },
                    }
                    : {
                        hourly: null,
                    }),
            },

        };

        console.log(jobDetailsNewCollectionData2,"<-----Job data from created form ")
        await createNewEntryForJobDetailsNewCollection(
            jobDetailsNewCollectionData2,
        )
            .then((data) => {
                toast.success("Job (New) updated successfully");
                navigate(`/jobs/${data}`)
            })
            .catch(() => {
                toast.error("There was an error updating Job (New)");
            });
    };

    const setIntialValuesToForm = (jobData: any) => {
        if (!jobData || (jobData && !Object.keys(jobData).length)) return;


        if (
            jobData?.jobDescription?.dayInLife &&
            jobData?.jobDescription?.dayInLife.length
        ) {
            setValue("jobSummary", jobData?.jobDescription?.dayInLife ?? "");
        }

    };

    const handleAddAboutThisJob = (
        code: string,
        labelLeft: string,
        labelRight: string,
        sliderValue: string,
        remove: boolean,
    ) => {
        let newAboutThisJob = [...(aboutThisJobWatch || [])];

        if (remove) {
            newAboutThisJob = newAboutThisJob.filter((obj: any) => obj.code !== code);

            setValue("aboutThisJob", newAboutThisJob);
            return;
        }

        const newData = {
            code,
            labelLeft,
            labelRight,
            value: Number(sliderValue),
        };

        if (newAboutThisJob.filter((obj: any) => obj.code === code).length) {
            newAboutThisJob = newAboutThisJob?.map((aboutThisJobObj: any) => {
                if (aboutThisJobObj.code !== code) {
                    return aboutThisJobObj;
                }

                return newData;
            });
        } else {
            newAboutThisJob.push(newData);
        }

        const newAboutThisJobSorted: any = [];

        aboutThisJobOptions.forEach((aboutThisJobOptionsObj: any) => {
            const isFound = newAboutThisJob.find(
                (obj: any) => obj.code === aboutThisJobOptionsObj.code,
            );
            if (isFound) {
                newAboutThisJobSorted.push(isFound);
            }
        });

        setValue("aboutThisJob", newAboutThisJobSorted);
    };

    useEffect(() => {
        if (job && !isInitialized) {
            setIntialValuesToForm(job);
            setIsInitialized(true);
        }
    }, [job, isInitialized]);

    // Handle the statusId change and dynamically update the schema
    useEffect(() => {
        if (isSubmittingJobFirstTime) {
            const newSchema = JobFormSchema5(isSubmittingJobFirstTime);
            setFormSchema(newSchema);
            setIntialValuesToForm(job);
        } else {
            const newSchema = JobFormSchema5(false);
            setFormSchema(newSchema);
        }
    }, [isSubmittingJobFirstTime, reset]);

    // if (!job) return <></>;

    // const fieldValues = getValues();
    // console.log(errors, "============>errors");
    // console.log(fieldValues, "============>fieldValues");

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-[60vw] flex flex-col justify-center items-center ">
                    <div className="w-[50vw] flex justify-center  ">
                        <div className="grid gap-1 px-10 grid-cols-1 md:grid-cols-1 mb-4 ">


                            <div className="mb-2">


                                <div className="relative text-instant-teams-blue-Main text-lg pl-1 pr-[20px] mb-[20px]">
                                    Please provide a brief summary of the Job Description in
                                    2-3 sentences *{isFieldLocked ? " - LOCKED" : null}
                                    <div className="absolute inset-y-0 right-0 top-[4px] flex items-start cursor-pointer">

                                        <InfoIcon
                                            tooltipText="This will be displayed as a short summary of the
                                               job on the Instant teams website."
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <Controller
                                        control={control}
                                        name="jobSummary"
                                        render={(field) => (
                                            <>
                                                <TextArea
                                                    {...field}
                                                    display=""
                                                    placeholder="Enter Job Summary"
                                                    readOnly={isFieldLocked}
                                                    error={errors?.jobSummary?.message}
                                                    maxLength={250}
                                                // required
                                                />
                                                <p className="w-full flex justify-end text-[12px] text-[#afafaf] -mt-3">
                                                    {(jobSummaryWatch?.length || 0) > 0 ? (
                                                        <>{jobSummaryWatch?.length} / 250 Characters</>
                                                    ) : (
                                                        <>Max 250 Characters</>
                                                    )}
                                                </p>
                                            </>
                                        )}
                                    />
                                </div>

                            </div>

                            <div className="mb-2">
                                {isSubmittingJobFirstTime ||
                                    job?.jobDescription?.whatsInItForYou === null ? (
                                    <>
                                        <div className="relative text-instant-teams-blue-Main text-lg pl-1 pr-[20px] mb-[20px]">
                                            What aspects of this job might surprise or excite
                                            potential candidates? *
                                            {isFieldLocked ? " - LOCKED" : null}
                                            <div className="absolute inset-y-0 right-0 top-[4px] flex items-start cursor-pointer">

                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-[10px]">
                                            {aspectsOfJob.map((aspectsOfJobObj: any) => {
                                                return (
                                                    <div key={aspectsOfJobObj.id}>
                                                        <Controller
                                                            control={control}
                                                            name="aspectsOfJob"
                                                            render={(field) => {
                                                                const { value, onChange, ...restField } = field;

                                                                const fieldValue = aspectsOfJobWatch || [];

                                                                return (
                                                                    <Checkbox
                                                                        {...restField}
                                                                        id={aspectsOfJobObj.id}
                                                                        label={aspectsOfJobObj.label}
                                                                        disabled={isFieldLocked}
                                                                        value={fieldValue.includes(
                                                                            aspectsOfJobObj.code,
                                                                        )}
                                                                        onChange={(isChecked: boolean) => {
                                                                            let newValue = [...fieldValue];

                                                                            if (isChecked) {
                                                                                newValue.push(aspectsOfJobObj.code);
                                                                            } else {
                                                                                newValue = newValue.filter(
                                                                                    (v) => v !== aspectsOfJobObj.code,
                                                                                );
                                                                            }

                                                                            const newValueSorted: any = [];
                                                                            aspectsOfJob.forEach(
                                                                                (aspectsOfJobObj: any) => {
                                                                                    if (
                                                                                        newValue.includes(
                                                                                            aspectsOfJobObj.code,
                                                                                        )
                                                                                    ) {
                                                                                        newValueSorted.push(
                                                                                            aspectsOfJobObj.code,
                                                                                        );
                                                                                    }
                                                                                },
                                                                            );

                                                                            onChange(newValueSorted);
                                                                        }}
                                                                        containerClassName="grid-cols-1"
                                                                        required
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                            <div className="flex flex-col gap-[20px]">
                                                <Controller
                                                    control={control}
                                                    name="isAspectsOfJobOther"
                                                    render={(field) => (
                                                        <Checkbox
                                                            {...field}
                                                            id="isAspectsOfJobOtherForAspectsOfJob"
                                                            label="Other"
                                                            disabled={isFieldLocked}
                                                            error={false}
                                                            required
                                                        />
                                                    )}
                                                />
                                                {isAspectsOfJobOther && (
                                                    <div className="mb-2 mt-[-10px]">
                                                        <Controller
                                                            control={control}
                                                            name="aspectsOfJobOther"
                                                            render={(field) => (
                                                                <>
                                                                    <TextArea
                                                                        {...field}
                                                                        display=""
                                                                        placeholder="Please write here"
                                                                        readOnly={isFieldLocked}
                                                                        error={errors?.aspectsOfJobOther?.message}
                                                                        required={false}
                                                                        maxLength={200}
                                                                    />
                                                                    <p className="w-full flex justify-end text-[12px] text-[#afafaf] -mt-3">
                                                                        {(aspectsOfJobOtherWatch?.length || 0) >
                                                                            0 ? (
                                                                            <>
                                                                                {aspectsOfJobOtherWatch?.length} / 200
                                                                                Characters
                                                                            </>
                                                                        ) : (
                                                                            <>Max 200 Characters</>
                                                                        )}
                                                                    </p>
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                )}
                                                {(errors as any)?.aspectsOfJobRoot && (
                                                    <p className="text-[0.8rem] font-medium text-[red] mt-1">
                                                        {(errors as any).aspectsOfJobRoot.message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <Controller
                                        control={control}
                                        name="whatsInItForYou"
                                        render={(field) => (
                                            <Wysiwyg
                                                {...field}
                                                rows={10}
                                                display="What's in it for you?"
                                                readOnly={isFieldLocked}
                                                placeholder={"What's in it for you?"}
                                                directions=""
                                                editorStyle={{ height: "240px" }}
                                                error={errors?.whatsInItForYou?.message}
                                                required
                                            />
                                        )}
                                    />
                                )}
                            </div>

                            <hr />

                            <div className="mb-2">
                                {isSubmittingJobFirstTime ||
                                    job?.jobDescription?.whatsInItForYou === null ? (
                                    <>
                                        <div className="relative text-instant-teams-blue-Main text-lg pl-1 pr-[20px] mb-[20px]">
                                            Tell Us About This Job: Use the sliders to give a quick
                                            snapshot of what to expect! *
                                            {isFieldLocked ? " - LOCKED" : null}
                                            <div className="absolute inset-y-0 right-0 top-[4px] flex items-start cursor-pointer">

                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-[10px]">
                                            <div className="text-[0.8rem] font-medium text-[#616161] -mt-[10px]">
                                                You can choose which “Instant Job Insights” appear on
                                                your post by checking the boxes next to each slider
                                            </div>
                                            <div className="text-[0.8rem] font-medium text-[#616161] -mt-[10px]">
                                                (Please choose a maximum of 5 out of the following).
                                            </div>

                                            {aboutThisJobOptions.map((option: any) => (
                                                <Controller
                                                    key={option.id}
                                                    name="aboutThisJob"
                                                    control={control}
                                                    render={(field) => {
                                                        const { value, onChange, ...restField } = field;

                                                        const fieldValue = aboutThisJobWatch || [];

                                                        const isDisabled =
                                                            fieldValue.length === 5 &&
                                                            !fieldValue.find(
                                                                (obj: any) => obj.code === option.code,
                                                            );

                                                        const valueOfField = fieldValue.find(
                                                            (obj: any) => obj.code === option.code,
                                                        );

                                                        return (
                                                            <SliderWithCheckbox
                                                                option={option}
                                                                value={valueOfField ? valueOfField.value : 50}
                                                                isChecked={valueOfField ? true : false}
                                                                onSelectionChange={(
                                                                    sliderValue: any,
                                                                    remove: boolean = false,
                                                                ) =>
                                                                    handleAddAboutThisJob(
                                                                        option.code,
                                                                        option.labelLeft, // Left label
                                                                        option.labelRight, // Right label
                                                                        sliderValue, // The selected slider value
                                                                        remove,
                                                                    )
                                                                }
                                                                readOnly={isFieldLocked}
                                                                disabled={isDisabled}
                                                            />
                                                        );
                                                    }}
                                                />
                                            ))}

                                            <div className="flex flex-col gap-[20px]">
                                                {(errors as any)?.aboutThisJob && (
                                                    <p className="text-[0.8rem] font-medium text-[red] mt-1">
                                                        {(errors as any).aboutThisJob.message}
                                                    </p>
                                                )}
                                                {(errors as any)?.aboutThisJobRoot && (
                                                    <p className="text-[0.8rem] font-medium text-[red] mt-1">
                                                        {(errors as any).aboutThisJobRoot.message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <Controller
                                        control={control}
                                        name="dayInLife"
                                        render={(field) => (
                                            <Wysiwyg
                                                {...field}
                                                rows={10}
                                                display="Day in the Life"
                                                readOnly={isFieldLocked}
                                                placeholder={"Day in the Life"}
                                                directions=""
                                                editorStyle={{ height: "240px" }}
                                                error={errors?.dayInLife?.message}
                                                required
                                            />
                                        )}
                                    />
                                )}
                            </div>

                            <hr />

                            <div className="mb-2">
                                {isSubmittingJobFirstTime ||
                                    job?.jobDescription?.whatsInItForYou === null ? (
                                    <>
                                        <div className="relative text-instant-teams-blue-Main text-lg pl-1 pr-[20px] mb-[20px]">
                                            What are the "must haves" for success in this role? *
                                            {isFieldLocked ? " - LOCKED" : null}
                                            <div className="absolute inset-y-0 right-0 top-[4px] flex items-start cursor-pointer">

                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-[10px]">
                                            <div className="text-[0.8rem] font-medium text-[#616161] -mt-[10px]">
                                                Choose 5 of the most important must haves
                                            </div>

                                            {mustHaves.map((mustHavesObj: any) => {
                                                return (
                                                    <div key={mustHavesObj.id}>
                                                        <Controller
                                                            control={control}
                                                            name="mustHavesCheckboxes"
                                                            render={(field) => {
                                                                const { value, onChange, ...restField } = field;

                                                                const fieldValue: string[] =
                                                                    mustHavesCheckboxesWatch || [];

                                                                const isFieldValue = fieldValue.includes(
                                                                    mustHavesObj?.code,
                                                                );

                                                                const isDisabled =
                                                                    fieldValue.length === 5 && !isFieldValue;

                                                                return (
                                                                    <Checkbox
                                                                        {...restField}
                                                                        id={mustHavesObj.id}
                                                                        label={mustHavesObj.label}
                                                                        disabled={isFieldLocked || isDisabled}
                                                                        value={isFieldValue}
                                                                        onChange={(isChecked: boolean) => {
                                                                            let newValue = [...fieldValue];

                                                                            if (isChecked) {
                                                                                newValue.push(mustHavesObj.code);
                                                                            } else {
                                                                                newValue = newValue.filter(
                                                                                    (v) => v !== mustHavesObj.code,
                                                                                );
                                                                            }

                                                                            const newValueSorted: any = [];
                                                                            mustHaves.forEach((mustHavesObj: any) => {
                                                                                if (
                                                                                    newValue.includes(mustHavesObj.code)
                                                                                ) {
                                                                                    newValueSorted.push(
                                                                                        mustHavesObj.code,
                                                                                    );
                                                                                }
                                                                            });

                                                                            onChange(newValueSorted);
                                                                        }}
                                                                        containerClassName="grid-cols-1"
                                                                        required
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            })}
                                            <div className="flex flex-col gap-[20px]">
                                                {(errors as any)?.mustHavesCheckboxes && (
                                                    <p className="text-[0.8rem] font-medium text-[red] mt-1">
                                                        {(errors as any).mustHavesCheckboxes.message}
                                                    </p>
                                                )}
                                                {(errors as any)?.mustHavesCheckboxesRoot && (
                                                    <p className="text-[0.8rem] font-medium text-[red] mt-1">
                                                        {(errors as any).mustHavesCheckboxesRoot.message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <Controller
                                        control={control}
                                        name="mustHaves"
                                        render={(field) => (
                                            <Wysiwyg
                                                {...field}
                                                rows={10}
                                                display="Must Haves/What You Bring to the Table"
                                                readOnly={isFieldLocked}
                                                placeholder={"Must Haves/What You Bring to the Table"}
                                                directions=""
                                                editorStyle={{ height: "240px" }}
                                                error={errors?.mustHaves?.message}
                                                required
                                            />
                                        )}
                                    />
                                )}
                            </div>




                        </div>
                    </div>
                </div>

                <div className="mt-24 w-full flex flex-row justify-between ">
                    <button
                        type="button"
                        disabled={isSubmitting}
                        className="h-[50px] w-[210px] rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-white bg-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                        onClick={() => { reset(); navigate("/jobs") }}
                    >
                        Cancel
                    </button>

                    <div className="flex justify-center items-center">
                        <button

                            onClick={() => setCurrentStep(currStep - 1)}
                            className="h-[50px] w-[210px] mr-5 rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 hover:border-[#1d5174] text-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="h-[50px] w-[210px] rounded-[70px] bg-[#15415E] px-5 py-3 hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
                        >
                            {isSubmitting ? "Saving..." : "Save"}
                        </button>
                    </div>


                </div>

            </form>
            <ToastAlert />
        </>
    );
}

export default JobInformationDescForm;
