import { useState, useEffect, Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useLookup } from "../../../../../../data/lookup";

import {
    getIsSubmittingJobFirstTime,
    updateEntryForJobDetailsNewCollection,
    useJobDetailsNewCollection
} from "../../../../../../data/jobDetailsNewCollection";
// import { getJobsNewSchema } from "../../../../../../schemas/JobsNewSchema"; 
import { getJobsNewSchema_emp } from "./JobInfoSchema_emp";
import GreenUnderlineHeading from "../../../../../custom-ui/headings/heading";
import { isLockedJobNew } from "../../../../../../util/jobs/jobUtils";
import Input from "../../../../../custom-ui/old-ui/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Wysiwyg from "../../../../../custom-ui/old-ui/Wysiwyg";
import SelectGeneric from "../../../../../custom-ui/old-ui/SelectGeneric";
import DateGeneric from "../../../../../custom-ui/old-ui/DateGeneric";
import { JobDetailsNewCollection } from "../../../../../../dataTypes/JobDetailsNewCollection";
import RadioGroup from "../../../../../custom-ui/old-ui/RadioGroup";
import toast from "react-hot-toast";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import Checkbox from "../../../../../custom-ui/old-ui/Checkbox";
import InputCurrency from "../../../../../custom-ui/old-ui/InputCurrency";
import TextArea from "../../../../../custom-ui/old-ui/TextArea";
import { useMemo } from "react";
import Tooltip from "../../../../../custom-ui/tooltip/tooltip";
import SliderWithCheckbox from "../../../../../custom-ui/old-ui/SliderWithCheckbox";
import FloatingLabelInput from "../../Component-UI/FloatPlaceholderInputField";
import InputCurrency_Emp from "../../Component-UI/InputCurrency_Emp";
import Input_Emp from "../../Component-UI/Input_Emp";
import RadioGroup_Emp from "../../Component-UI/RadioGroup_Emp";
import InfoIcon from "../../../../../seeker/details/New_UI/registration/InfoIcon";
import SelectGeneric_emp from "../../Component-UI/AutoSaveGeneric";
import Select from "../../../../../custom-ui/new-ui/Select";
import { useAuth } from "../../../../../../providers/auth";
import { useEmployer } from "../../../../../../data/employer";

function JobInformation({ modalType, toggleModal }: any) {
    const params = useParams();
    const jobId = params.id ?? "";
    const { auth, user: { claims } } = useAuth();
    const { data: empData } = useEmployer(claims?.employerId || "")
    const [error, setError] = useState()
    const [isInitialized, setIsInitialized] = useState(false);
    const [formSchema, setFormSchema] = useState(getJobsNewSchema_emp(false));

    const { data: job } = useJobDetailsNewCollection(jobId);
    const { data: workSetting = [] } = useLookup("workSetting");
    const { data: workType = [] } = useLookup("workType");
    const { data: workTerm = [] } = useLookup("workTerm");
    const { data: payRateTypes = [] } = useLookup("payRateType");


    const [includeDate, setIncludeDate] = useState(false);
    const isFieldLocked = isLockedJobNew(job?.jobState);

    useEffect(() => {
        setIncludeDate(job?.startDateInclude)
    }, [job])

    const lockedJobStatusToolTipText = useMemo(() => {
        let jobState = job?.jobState;

        if (["closed_filled", "closed_cancelled"].includes(jobState)) {
            jobState = "closed";
        } else if (jobState === "on_hold") {
            jobState = "on hold";
        } else {
            jobState = "live";
        }
        return jobState;
    }, [job]);

    const SECTION_LOCKED_MESSAGE = `This section cannot be changed while the job status is ${lockedJobStatusToolTipText}.`;

    type FormValues = z.infer<ReturnType<typeof getJobsNewSchema_emp>>;

    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            jobName: "",
            startDate: "",
            openings: "",
            applicationFlow: "",
            workSettings: "",
            workType: "",
            workTerm: "",
            hoursPerWeekMin: "",
            hoursPerWeekMax: "",
            payRateType: "",
            salaryMin: "",
            salaryMax: "",
            hourlyMin: "",
            hourlyMax: "",
            isCommission: false,
            commission: "",
            unpaidInternship: "",
            additionalBenifits: job?.payRate?.additionalBenifits || ""
        },
        reValidateMode: "onBlur",
    });

    const isSubmittingJobFirstTime = useMemo(
        () => getIsSubmittingJobFirstTime(job),
        [job],
    );

    const {
        handleSubmit,
        setValue,
        getValues,
        control,
        reset,
        watch,
        formState: { errors, isSubmitting },
    } = form;

    // Watch for the value of payRateType
    const payRateType = watch("payRateType");
    // Watch for the value of isCommission
    const isCommission = watch("isCommission");
    // Watch for the value of aspectsOfJob
    const additionalBenifits_watch = watch("additionalBenifits");


    useEffect(() => {
        if (additionalBenifits_watch) {
            setValue("commission", "");
        }

    }, [additionalBenifits_watch]);



    const onSubmit = async ({ ...values }: FormValues) => {

        if (includeDate) {
            if (values?.startDate) {

            }
            else {
                setError("Start Date is Required")
            }

        }
        const {
            jobSummary,
            whatsInItForYou,
            dayInLife,
            mustHaves,
            startDate,
            hoursPerWeekMin,
            hoursPerWeekMax,
            payRateType,
            salaryMin,
            salaryMax,
            hourlyMin,
            hourlyMax,
            commission,
            unpaidInternship,
            additionalBenifits,
            ...restValues
        } = values;

        const jobDetailsNewCollectionData: any = {
            ...restValues,
            // jobName: title,
            companyId: job.companyId,
            creationDate: job.creationDate,
            jobState: job.jobState,
            startDateInclude: includeDate,
            // giving null placeholder values to the fields which have not been filled yet
            // applicationFlow: null,
            applyNowCounter: job?.applyNowCounter || 0,
            clicksCounter: job?.clicksCounter || 0,
            startDate: startDate || "",
            closedFilledBy: "",
            closedReason: "",
            companyFullName: job?.companyFullName,
            companyLogo: job?.companyLogo,
            companyHQ: job?.companyHQ,
            hoursPerWeek: {
                min: Number(hoursPerWeekMin),
                max: Number(hoursPerWeekMax),
            },
            instantTeamJobs: null,
            makeLiveDate: job.makeLiveDate,
            openings: Number(values.openings),
            payRate: {
                type: payRateType,
                ...(["salary", "hourly", "commission"].includes(payRateType) &&
                    commission !== "" &&
                    commission !== undefined
                    ? {
                        additionalBenifits: additionalBenifits || "",
                        commission: commission,
                    }
                    : {
                        commission: null,
                    }),
                ...(payRateType === "unpaid_internship" &&
                    unpaidInternship !== "" &&
                    unpaidInternship !== undefined
                    ? {
                        unpaidInternship: unpaidInternship,
                    }
                    : {
                        unpaidInternship: null,
                    }),
                ...(payRateType === "salary" &&
                    salaryMin !== "" &&
                    salaryMax !== "" &&
                    salaryMin !== undefined &&
                    salaryMax !== undefined
                    ? {
                        salary: {
                            min: Number(salaryMin),
                            max: Number(salaryMax),
                        },
                    }
                    : {
                        salary: null,
                    }),
                ...(payRateType === "hourly" &&
                    hourlyMin !== "" &&
                    hourlyMax !== "" &&
                    hourlyMin !== undefined &&
                    hourlyMax !== undefined
                    ? {
                        hourly: {
                            min: Number(hourlyMin),
                            max: Number(hourlyMax),
                        },
                    }
                    : {
                        hourly: null,
                    }),
            },
        };

        await updateEntryForJobDetailsNewCollection(
            jobId,
            jobDetailsNewCollectionData,
        )
            .then((data) => {
                toast.success("Job (New) updated successfully");
                toggleModal()
            })
            .catch(() => {
                toast.error("There was an error updating Job (New)");
            });
    };

    const setIntialValuesToForm = (jobData: any) => {
        if (!jobData || (jobData && !Object.keys(jobData).length)) return;
        setValue("payRateType", jobData?.payRate?.type ?? "");
        setValue("jobName", jobData?.jobName ?? "");
        setValue("startDate", job?.startDate?.toDate?.() ?? undefined);
        setValue("openings", empData?.currentPlan === "annual" ? jobData?.openings ?? "" : 1);
        setValue("applicationFlow", jobData?.applicationFlow ?? "");
        setValue("workSettings", jobData?.workSettings ?? "");
        setValue("workTerm", jobData?.workTerm ?? "");
        setValue("workType", jobData?.workType ?? "");
        setValue("hoursPerWeekMin", jobData?.hoursPerWeek?.min ?? "");
        setValue("hoursPerWeekMax", jobData?.hoursPerWeek?.max ?? "");
        setIncludeDate(job?.startDateInclude);
        setValue("jobSummary", jobData?.jobDescription?.jobSummary ?? "");
        setValue("whatsInItForYou", jobData?.jobDescription?.whatsInItForYou ?? "");
        setValue("dayInLife", jobData?.jobDescription?.dayInLife ?? "");

        if (
            jobData?.jobDescription?.dayInLife &&
            jobData?.jobDescription?.dayInLife.length
        ) {
            setValue("jobSummary", jobData?.jobDescription?.dayInLife ?? "");
        }
        setValue("mustHaves", jobData?.jobDescription?.mustHaves ?? "");
        setTimeout(() => {
            if (jobData?.payRate?.type === "salary") {
                setValue("salaryMin", String(jobData?.payRate?.salary?.min) ?? "");
                setValue("salaryMax", String(jobData?.payRate?.salary?.max) ?? "");
                setValue("commission", jobData?.payRate?.commission ?? null);
                setValue("additionalBenifits", jobData?.payRate?.additionalBenifits ?? null);
            } else if (jobData?.payRate?.type === "hourly") {
                setValue("hourlyMin", String(jobData?.payRate?.hourly?.min) ?? "");
                setValue("hourlyMax", String(jobData?.payRate?.hourly?.max) ?? "");
                setValue("commission", jobData?.payRate?.commission ?? null);
                setValue("additionalBenifits", jobData?.payRate?.additionalBenifits ?? null);
            } else if (jobData?.payRate?.type === "commission") {
                setValue("commission", jobData?.payRate?.commission ?? "");
            } else if (jobData?.payRate?.type === "unpaid_internship") {
                setValue("unpaidInternship", jobData?.payRate?.unpaidInternship ?? "");
            }
            setTimeout(() => {
                setValue(
                    "isCommission",
                    jobData?.payRate?.commission?.length ? true : false,
                );
                setTimeout(() => {
                    if (jobData?.payRate?.type === "salary") {
                        setValue("commission", jobData?.payRate?.commission ?? null);
                    } else if (jobData?.payRate?.type === "hourly") {
                        setValue("commission", jobData?.payRate?.commission ?? null);
                    }


                }, 100);
            }, 500);
        }, 1000);
    };

    const handleAddAboutThisJob = (
        code: string,
        labelLeft: string,
        labelRight: string,
        sliderValue: string,
        remove: boolean,
    ) => {

    };

    useEffect(() => {
        if (job && !isInitialized) {
            setIntialValuesToForm(job);
            setIsInitialized(true);
        }
    }, [job, isInitialized]);

    // Handle the statusId change and dynamically update the schema
    useEffect(() => {
        if (isSubmittingJobFirstTime) {
            const newSchema = getJobsNewSchema_emp(isSubmittingJobFirstTime);
            setFormSchema(newSchema);
            setIntialValuesToForm(job);
        } else {
            const newSchema = getJobsNewSchema_emp(false);
            setFormSchema(newSchema);
        }
    }, [isSubmittingJobFirstTime, reset]);

    if (!job) return <></>;

    // const fieldValues = getValues();
    // console.log(errors, "============>errors");
    // console.log(fieldValues, "============>fieldValues");

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-row justify-center">
                    <div className="w-auto min-h-screen max-w-[850px]">
                        <div className="grid gap-1 px-10 grid-cols-1 md:grid-cols-1 mb-4 mt-4">
                            {modalType === "jobInfo" && (<>
                                <h1 className="text-instant-teams-blue-Main text-xl mb-5  font-[400] mt-5">
                                    Job Information
                                </h1>

                                <Controller
                                    control={control}
                                    name="jobName"
                                    render={(field) => (

                                        <FloatingLabelInput
                                            {...field}
                                            display="Job Title"
                                            placeholder="Enter Job Title"
                                            readOnly={isFieldLocked}
                                            error={errors?.jobName?.message}
                                            required
                                        />
                                    )}
                                />


                                {empData?.currentPlan === "annual" && <div className="flex justify-center items-center">
                                    <Controller
                                        control={control}
                                        name="openings"
                                        render={(field) => {
                                            const { value, onChange, ...restField } = field;

                                            return (
                                                <FloatingLabelInput
                                                    {...restField}
                                                    value={value === "" ? "" : Number(value)}
                                                    onChange={(e: any) => {
                                                        if (isNaN(Number(e.target.value))) {
                                                            return;
                                                        }

                                                        const valueAsNumber =
                                                            e.target.value === "" ? "" : Number(e.target.value);

                                                        onChange(valueAsNumber);
                                                    }}
                                                    display="Openings"
                                                    placeholder="Enter Openings"
                                                    readOnly={isFieldLocked}
                                                    error={errors?.openings?.message}
                                                    required
                                                />
                                            );
                                        }}
                                    />
                                    <InfoIcon
                                        tooltipText="The number of openings is also
                                        sometimes known as the number of
                                        “seats” for a given job requisition."
                                        viewDown={true} />
                                </div>}


                                <h1 className="text-instant-teams-blue-Main text-xl mb-3  font-[400] mt-2">
                                    Job Flow
                                </h1><div className="flex justify-center">
                                    <Controller
                                        control={control}
                                        name="applicationFlow"
                                        render={(field) => (
                                            <FloatingLabelInput
                                                {...field}
                                                display="Apply Link"
                                                placeholder="Enter Application Flow"
                                                readOnly={isFieldLocked}
                                                error={errors?.applicationFlow?.message}
                                                required
                                            />



                                        )}
                                    />
                                    <InfoIcon tooltipText="Here is where you place the link
                                            that will direct the applicant off of
                                            Instant Teams and towards filling
                                            out your full job application,
                                            wherever it is housed (i.e. your
                                            company’s ATS)." viewDown={true} />
                                </div>
                                <div className="flex  w-auto justify-start items-center">
                                    <h1 className="text-instant-teams-blue-Main text-lg flex mt-2">
                                        Start Date <span className="text-red-500 text-base mt-1">*</span>
                                    </h1>
                                    <InfoIcon
                                        tooltipText={"Please select include if you would like to post your desired start date for this (these) candidate."}
                                    />

                                </div>

                                {/* Radio Buttons for Include Date and Not Include Date */}
                                <div className="mt-2 my-3 flex ">
                                    <div className="flex items-center mb-2">
                                        <input
                                            type="radio"
                                            id="includeDate"
                                            name="dateOption"
                                            value="includeDate"
                                            checked={includeDate}
                                            onChange={() => setIncludeDate(true)}
                                            className="mr-2 "
                                        />
                                        <label className="mr-5" htmlFor="includeDate">Include Date</label>
                                    </div>
                                    <div className="flex items-center mb-2">
                                        <input
                                            type="radio"
                                            id="notIncludeDate"
                                            name="dateOption"
                                            value="notIncludeDate"
                                            checked={!includeDate}
                                            onChange={() => setIncludeDate(false)}
                                            className="mr-2"
                                        />
                                        <label htmlFor="notIncludeDate">Not Include Date</label>
                                    </div>
                                </div>

                                {/* Conditionally Render Start Date */}
                                {includeDate && (
                                    <Controller
                                        control={control}
                                        name="startDate"
                                        render={(field) => (
                                            <DateGeneric
                                                {...field}
                                                display="Start Date"
                                                timestamp={true}
                                                readOnly={isFieldLocked}
                                                error={errors?.startDate?.message}
                                                required
                                            />
                                        )}
                                    />
                                )}
                                {error && <p className="text-red-500  text-base" >{error}</p>}



                                <h1 className="text-instant-teams-blue-Main text-xl  font-[500] mt-2">
                                    Work
                                </h1>
                                <h1 className="text-instant-teams-blue-Main text-lg py-3 pb-4 flex  mt-2">
                                    Work Settings <span className="text-red-500 text-base mt-1">*</span>
                                </h1>
                                <Controller
                                    control={control}
                                    name="workSettings"
                                    render={(field) => (
                                        <RadioGroup_Emp
                                            {...field}
                                            options={workSetting}
                                            disabled={isFieldLocked}
                                            error={errors?.workSettings?.message}
                                            required
                                        />
                                    )}
                                />

                                <h1 className="text-instant-teams-blue-Main text-lg py-3 pb-4 flex font-[400] mt-2">
                                    Work Type <span className="text-red-500 text-base mt-1">*</span>
                                </h1>
                                <Controller
                                    control={control}
                                    name="workType"
                                    render={(field) => (
                                        <RadioGroup_Emp
                                            {...field}
                                            options={workType}
                                            disabled={isFieldLocked}
                                            error={errors?.workType?.message}
                                            required
                                        />
                                    )}
                                />
                                <h1 className="text-instant-teams-blue-Main text-lg py-3 pb-4 flex  mt-2">
                                    Work Term<span className="text-red-500 text-base mt-1">*</span>
                                </h1>

                                <Controller
                                    control={control}
                                    name="workTerm"
                                    render={(field) => (
                                        <RadioGroup_Emp
                                            {...field}
                                            options={workTerm}
                                            disabled={isFieldLocked}
                                            error={errors?.workTerm?.message}
                                            required
                                        />
                                    )}
                                />
                                <h1 className="text-instant-teams-blue-Main text-lg py-3 pb-4 flex  mt-2">
                                    Work Per Week (Hrs)<span className="text-red-500 text-base ">*</span>
                                </h1>

                                <div className="flex w-[65%] mt-1">
                                    <Controller
                                        control={control}
                                        name="hoursPerWeekMin"
                                        render={(field) => {
                                            const { value, onChange, ...restField } = field;

                                            return (
                                                <Input_Emp
                                                    {...restField}
                                                    value={value === "" ? "" : value}
                                                    onChange={(e: any) => {
                                                        const inputValue = e.target.value;
                                                        const isValidInput = /^\d*\.?\d{0,1}$/.test(
                                                            inputValue,
                                                        );
                                                        if (!isValidInput) return;
                                                        onChange(inputValue);
                                                    }}
                                                    display="Min (Hrs)"
                                                    placeholder="Min (Hrs)"
                                                    readOnly={isFieldLocked}
                                                    error={errors?.hoursPerWeekMin?.message}
                                                    required
                                                />
                                            );
                                        }}
                                    />
                                    <Controller
                                        control={control}
                                        name="hoursPerWeekMax"
                                        render={(field) => {
                                            const { value, onChange, ...restField } = field;

                                            return (
                                                <Input_Emp
                                                    {...restField}
                                                    value={value === "" ? "" : value}
                                                    onChange={(e: any) => {
                                                        const inputValue = e.target.value;
                                                        const isValidInput = /^\d*\.?\d{0,1}$/.test(
                                                            inputValue,
                                                        );
                                                        if (!isValidInput) return;
                                                        onChange(inputValue);
                                                    }}
                                                    display="Max (Hrs)"
                                                    placeholder="Max (Hrs)"
                                                    readOnly={isFieldLocked}
                                                    error={errors?.hoursPerWeekMax?.message}
                                                    required
                                                />
                                            );
                                        }}
                                    />
                                </div>
                                <h1 className="text-instant-teams-blue-Main text-xl   font-[400] mt-2">
                                    Salary
                                </h1>
                                <h1 className="text-instant-teams-blue-Main text-lg py-3 pb-4 flex  mt-2">
                                    Pay Rate<span className="text-red-500 text-base mt-1">*</span>
                                </h1>

                                <Controller
                                    control={control}
                                    name="payRateType"
                                    render={(field) => (
                                        <RadioGroup_Emp
                                            {...field}
                                            options={payRateTypes}
                                            disabled={isFieldLocked}
                                            error={errors?.payRateType?.message}
                                            required
                                        />
                                    )}
                                />

                                {payRateType === "salary" && (
                                    <div className="flex mt-2 flex-col">
                                        <div className="flex w-[71%] mt-4">
                                            <Controller
                                                control={control}
                                                name="salaryMin"
                                                render={(field) => {
                                                    return (
                                                        <InputCurrency_Emp
                                                            {...field}
                                                            display="Min"
                                                            placeholder="Min"
                                                            readOnly={isFieldLocked}
                                                            error={errors?.salaryMin?.message}
                                                            required
                                                        />
                                                    );
                                                }}
                                            />

                                            <Controller
                                                control={control}
                                                name="salaryMax"
                                                render={(field) => {
                                                    const { value, onChange, ...restField } = field;

                                                    return (
                                                        <InputCurrency_Emp
                                                            {...field}
                                                            display="Max"
                                                            placeholder="Max"
                                                            readOnly={isFieldLocked}
                                                            error={errors?.salaryMax?.message}
                                                            required
                                                        />
                                                    );
                                                }}
                                            />

                                        </div>
                                        <div className="flex   mt-2">

                                        </div>
                                        <div className="flex flex-col gap-[20px] mt-2">
                                            <Controller
                                                control={control}
                                                name="isCommission"
                                                render={(field) => (
                                                    <Checkbox
                                                        {...field}
                                                        id="isCommissionForSalary"
                                                        label="Additional Benefits"
                                                        disabled={isFieldLocked}
                                                        error={errors?.workSettings?.message}
                                                        required
                                                    />
                                                )}
                                            />
                                            {isCommission && (
                                                <div className="mb-2">
                                                    <div className="my-5">
                                                        <Controller
                                                            control={control}
                                                            name="additionalBenifits"
                                                            render={(field) => (
                                                                <Select
                                                                    {...field}
                                                                    options={[{
                                                                        "code": "empComm",
                                                                        "description": "PostJob",
                                                                        "group": "emp_postjob",
                                                                        "icon": null,
                                                                        "isActive": true,
                                                                        "label": "Commission",
                                                                        "sortOrder": 1
                                                                    }, {
                                                                        "code": "empBO",
                                                                        "description": "PostJob",
                                                                        "group": "emp_PostJob",
                                                                        "icon": null,
                                                                        "isActive": true,
                                                                        "label": "Bonus",
                                                                        "sortOrder": 2
                                                                    }]}
                                                                    display="Benefits Type"
                                                                    placeholder="Select Additional Benifits"
                                                                    disabled={isFieldLocked}
                                                                    error={errors?.additionalBenifits?.message}
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                    <Controller
                                                        control={control}
                                                        name="commission"
                                                        render={(field) => (
                                                            <Wysiwyg
                                                                {...field}
                                                                rows={10}
                                                                display={additionalBenifits_watch === "empBO" ? "Bonus" : "Commission"}
                                                                readOnly={isFieldLocked}
                                                                placeholder={"Enter Commission"}
                                                                directions=""
                                                                editorStyle={{ height: "240px" }}
                                                                error={errors?.commission?.message}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {payRateType === "hourly" && (
                                    <div className="flex mt-2 flex-col">
                                        <div className="flex w-[71%] mt-4">
                                            <Controller
                                                control={control}
                                                name="hourlyMin"
                                                render={(field) => {
                                                    return (
                                                        <InputCurrency_Emp
                                                            {...field}
                                                            display="Min"
                                                            placeholder="Min"
                                                            readOnly={isFieldLocked}
                                                            error={errors?.hourlyMin?.message}
                                                            required
                                                        />
                                                    );
                                                }}
                                            />
                                            <Controller
                                                control={control}
                                                name="hourlyMax"
                                                render={(field) => {
                                                    return (
                                                        <InputCurrency_Emp
                                                            {...field}
                                                            display="Max"
                                                            placeholder="Max"
                                                            readOnly={isFieldLocked}
                                                            error={errors?.hourlyMax?.message}
                                                            required
                                                        />
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-[20px] mt-4">
                                            <Controller
                                                control={control}
                                                name="isCommission"
                                                render={(field) => (
                                                    <Checkbox
                                                        {...field}
                                                        id="isCommissionForHourly"
                                                        label="Additonal Benefits"
                                                        disabled={isFieldLocked}
                                                        error={errors?.workSettings?.message}
                                                        required
                                                    />
                                                )}
                                            />
                                            {isCommission && (
                                                <div className="mb-2">
                                                    <div className="my-5">
                                                        <Controller
                                                            control={control}
                                                            name="additionalBenifits"
                                                            render={(field) => (
                                                                <Select
                                                                    {...field}
                                                                    options={[{
                                                                        "code": "empComm",
                                                                        "description": "PostJob",
                                                                        "group": "emp_postjob",
                                                                        "icon": null,
                                                                        "isActive": true,
                                                                        "label": "Commission",
                                                                        "sortOrder": 1
                                                                    }, {
                                                                        "code": "empBO",
                                                                        "description": "PostJob",
                                                                        "group": "emp_PostJob",
                                                                        "icon": null,
                                                                        "isActive": true,
                                                                        "label": "Bonus",
                                                                        "sortOrder": 2
                                                                    }]}
                                                                    display="Benefits Type"
                                                                    placeholder="Select Additional Benifits"
                                                                    disabled={isFieldLocked}
                                                                    error={errors?.additionalBenifits?.message}
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                    <Controller
                                                        control={control}
                                                        name="commission"
                                                        render={(field) => (
                                                            <Wysiwyg
                                                                {...field}
                                                                rows={10}
                                                                display={additionalBenifits_watch === "empBO" ? "Bonus" : "Commission"}
                                                                readOnly={isFieldLocked}
                                                                placeholder={"Enter Commission"}
                                                                directions=""
                                                                editorStyle={{ height: "240px" }}
                                                                error={errors?.commission?.message}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {payRateType === "commission" && (
                                    <div className="mt-2">
                                        {/* <Controller
                    control={control}
                    name="commission"
                    render={(field) => (
                      <TextArea
                        {...field}
                        display="Commission"
                        placeholder="Enter Commission"
                        readOnly={isFieldLocked}
                        error={errors?.commission?.message}
                        required
                      />
                    )}
                  /> */}

                                        <Controller
                                            control={control}
                                            name="commission"
                                            render={(field) => (
                                                <Wysiwyg
                                                    {...field}
                                                    rows={10}
                                                    display="Commission"
                                                    readOnly={isFieldLocked}
                                                    placeholder={"Enter Commission"}
                                                    directions=""
                                                    editorStyle={{ height: "240px" }}
                                                    error={errors?.commission?.message}
                                                    required
                                                />
                                            )}
                                        />
                                    </div>
                                )}

                                {payRateType === "unpaid_internship" && (
                                    <div className="mt-2">
                                        {/* <Controller
                    control={control}
                    name="unpaidInternship"
                    render={(field) => (
                      <TextArea
                        {...field}
                        display="Free Internship"
                        placeholder="Enter Free Internship"
                        readOnly={isFieldLocked}
                        error={errors?.unpaidInternship?.message}
                        required
                      />
                    )}
                  /> */}
                                    </div>
                                )}

                            </>)}

                            {/* job description Begins */}



                            {!isFieldLocked && (
                                <div className="mt-3 flex flex-row justify-center gap-[36px]">
                                    <button
                                        type="button"
                                        disabled={isSubmitting}
                                        className="h-[60px] w-[210px] rounded-[70px] border-[1px] border-[#15415E] py-[20px] px-[70px] hover:border-[#1d5174] text-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                                        onClick={() => toggleModal()}
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="h-[60px] w-auto rounded-[70px] bg-[#15415E] py-[20px] px-[70px] hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
                                    >
                                        {isSubmitting ? "Saving..." : "Save Changes"}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>
            <ToastAlert />
        </>
    );
}

export default JobInformation;
