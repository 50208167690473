import React from 'react';
import { cn } from '../../../../../../util/cn';
import BulletTextWrapper from '../CurrentPlan/BulletTextWrapper';

const AnnualSubcriptionCard = ({ isSelected = false, noOfJobs, planAmt, setSelected }: any) => {
    return (
        <div className="mt-3">
            <div
                className={cn(
                    "flex flex-col w-[280px] min-h-[510px] pt-[35px] pb-[30px] border-[1px] border-[rgba(138, 160, 174, 0.5)] rounded-[20px] items-center cursor-pointer",
                    {
                        "bg-gradient-to-r from-[#0B619A] to-[#4DAAE8]": isSelected,
                    }
                )}
                onClick={() => setSelected(noOfJobs)}
            >
                <p
                    className={cn(
                        "font-[Gilroy-Bold] font-[400] text-[24px] leading-[29.71px]",
                        {
                            "text-[#15415E]": !isSelected,
                            "text-[#FFFFFF]": isSelected,
                        }
                    )}
                >
                    Annual Subscription
                </p>
                <p
                    className={cn(
                        "mt-[10px] font-[Gilroy-Medium] text-center font-[400] text-[16px] leading-[19.41px]",
                        {
                            "text-[#15415E]": !isSelected,
                            "text-[#FFFFFF]": isSelected,
                        }
                    )}
                >
                    <span className='font-extrabold'>{noOfJobs}</span>  <br /> 30-day job Posting  <br /> Per Month
                </p>
                <div
                    className={cn(
                        "mt-4 mx-[30px] flex-1 flex flex-col gap-[15px] font-[Gilroy-Regular] font-[400] text-[12px] leading-[14.4px]",
                        {
                            "text-[#8AA0AE]": !isSelected,
                            "text-[#FFFFFF]": isSelected,
                        }
                    )}
                >
                    {noOfJobs === 5 && (
                        <>
                            <BulletTextWrapper>Includes five(5) 30-day job postings</BulletTextWrapper>
                            <BulletTextWrapper>Jobs must be used within each 30-day period</BulletTextWrapper>
                            <BulletTextWrapper>Includes employer profile and logo</BulletTextWrapper>
                            <BulletTextWrapper>Candidates are directed to your ATS</BulletTextWrapper>
                            <BulletTextWrapper>
                                Automatic renewal and billing cancel anytime
                            </BulletTextWrapper>
                        </>
                    )}

                    {noOfJobs === 10 && (
                        <>
                            <BulletTextWrapper>Includes ten(10) 30-day job postings</BulletTextWrapper>
                            <BulletTextWrapper>Jobs must be used within each 30-day period</BulletTextWrapper>
                            <BulletTextWrapper>Includes employer profile and logo</BulletTextWrapper>
                            <BulletTextWrapper>Candidates are directed to your ATS</BulletTextWrapper>
                            <BulletTextWrapper>
                                Automatic renewal and billing, cancel anytime
                            </BulletTextWrapper>
                        </>
                    )}

                    {noOfJobs === 20 && (
                        <>
                            <BulletTextWrapper>Includes twenty(20) 30-day job postings</BulletTextWrapper>
                            <BulletTextWrapper>Jobs must be used within each 30-day period</BulletTextWrapper>
                            <BulletTextWrapper>Includes employer profile and logo</BulletTextWrapper>
                            <BulletTextWrapper>Candidates are directed to your ATS</BulletTextWrapper>
                            <BulletTextWrapper>
                                Automatic renewal and billing, cancel anytime
                            </BulletTextWrapper>
                        </>
                    )}
                </div>

                <p
                    className={cn(
                        "flex flex-row justify-center mt-[25px] w-[250px] font-[Gilroy-Bold] font-[400] text-[40px] leading-[49.52px]",
                        {
                            "text-[#15415E]": !isSelected,
                            "text-[#FFFFFF]": isSelected,
                        }
                    )}
                >
                    ${planAmt}
                </p>
                <p
                    className={cn(
                        "mt-4 mx-[21px] font-[Gilroy-Regular] font-[400] text-[12px] leading-[12px] text-center",
                        {
                            "text-[#15415E80]": !isSelected,
                            "text-[#FFFFFF]": isSelected,
                        }
                    )}
                >
                    &nbsp;All packages include direct ATS apply, IT Marketplace badge for company
                    website and analytics
                </p>
            </div>
        </div>
    );
};

export default AnnualSubcriptionCard;
