import { useState, useEffect, Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useLookup } from "../../../../../../data/lookup";
import { getIsSubmittingJobFirstTime } from "../../../../../../data/jobDetailsNewCollection";
import GreenUnderlineHeading from "../../../../../custom-ui/headings/heading";
import { isLockedJobNew } from "../../../../../../util/jobs/jobUtils";
import Input from "../../../../../custom-ui/old-ui/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
    getJobsNewSchema_emp
} from "./JobFormSchema";
import Wysiwyg from "../../../../../custom-ui/old-ui/Wysiwyg";
import SelectGeneric from "../../../../../custom-ui/old-ui/SelectGeneric";
import DateGeneric from "../../../../../custom-ui/old-ui/DateGeneric";
import { JobDetailsNewCollection } from "../../../../../../dataTypes/JobDetailsNewCollection";
import RadioGroup from "../../../../../custom-ui/old-ui/RadioGroup";
import toast from "react-hot-toast";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import Checkbox from "../../../../../custom-ui/old-ui/Checkbox";
import { useMemo } from "react";
import FloatingLabelInput from "../../Component-UI/FloatPlaceholderInputField";
import InputCurrency_Emp from "../../Component-UI/InputCurrency_Emp";
import Input_Emp from "../../Component-UI/Input_Emp";
import RadioGroup_Emp from "../../Component-UI/RadioGroup_Emp";
import { useJobDetailsNewCollection } from "../../../../../../data/jobDetailsNewCollection";
import { useNavigate } from "react-router-dom";
import InfoIcon from "../../../../../dashboard/AdminMetricDashboard/InfoTextWithIcon";
import { useAuth } from "../../../../../../providers/auth";
import { useEmployer } from "../../../../../../data/employer";


function AddJobStep1({ currStep, setCurrentStep, formvalues, setFormvalues }: any) {
    const { auth, user:{claims} } = useAuth();
    const {data:empData} = useEmployer(claims?.employerId || "")
    console.log(empData?.currentPlan)
    const navigate = useNavigate()
    const params = useParams();
    const jobId = params.id ?? "vUN2qyluYqwzeaEmduqU";

    const [isInitialized, setIsInitialized] = useState(false);
    const [formSchema, setFormSchema] = useState(getJobsNewSchema_emp(false));

    const { data: job } = useJobDetailsNewCollection(jobId);

 


 

    const isFieldLocked = isLockedJobNew(job?.jobState);

    const lockedJobStatusToolTipText = useMemo(() => {
        let jobState = job?.jobState;

        if (["closed_filled", "closed_cancelled"].includes(jobState)) {
            jobState = "closed";
        } else if (jobState === "on_hold") {
            jobState = "on hold";
        } else {
            jobState = "live";
        }
        return jobState;
    }, [job]);

    const SECTION_LOCKED_MESSAGE = `This section cannot be changed while the job status is ${lockedJobStatusToolTipText}.`;

    type FormValues = z.infer<ReturnType<typeof getJobsNewSchema_emp>>;

    const form = useForm<FormValues>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            jobName: formvalues?.jobName || "",
            // solutionType: "",
            // startDate: "",
            openings: formvalues?.openings || 1,
            // applicationFlow: "",
    

        },
        reValidateMode: "onBlur",
    });

    const isSubmittingJobFirstTime = useMemo(
        () => getIsSubmittingJobFirstTime(job),
        [job],
    );

    const {
        handleSubmit,
        setValue,
        getValues,
        control,
        reset,
        watch,
        formState: { errors, isSubmitting },
    } = form;

    // Watch for the value of payRateType
    const payRateType = watch("payRateType");
    // Watch for the value of isCommission
    const isCommission = watch("isCommission");
    // Watch for the value of aspectsOfJob


    const onSubmit = async ({ ...values }: FormValues) => {
        setFormvalues(values)
        setCurrentStep(currStep + 1)
        // const {
        //     jobSummary,
        //     whatsInItForYou,
        //     dayInLife,
        //     mustHaves,

        //     aspectsOfJob = [],
        //     aspectsOfJobOther = "",
        //     isAspectsOfJobOther,

        //     aboutThisJob = [],

        //     mustHavesCheckboxes = [],

        //     hoursPerWeekMin,
        //     hoursPerWeekMax,
        //     payRateType,
        //     salaryMin,
        //     salaryMax,
        //     hourlyMin,
        //     hourlyMax,
        //     commission,
        //     unpaidInternship,
        //     ...restValues
        // } = values;

        // const jobDetailsNewCollectionData: any = {
        //     ...restValues,
        //     // jobName: title,
        //     companyId: job.companyId,
        //     creationDate: job.creationDate,
        //     jobState: job.jobState,

        //     // giving null placeholder values to the fields which have not been filled yet
        //     // applicationFlow: null,
        //     applyNowCounter: 0,
        //     clicksCounter: 0,
        //     closedFilledBy: "",
        //     closedReason: "",
        //     companyFullName: job.companyFullName,
        //     companyLogo: job.companyLogo,
        //     companyHQ: job.companyHQ,
        //     hoursPerWeek: {
        //         min: Number(hoursPerWeekMin),
        //         max: Number(hoursPerWeekMax),
        //     },
        //     instantTeamJobs: null,
        //     makeLiveDate: job.makeLiveDate,
        //     openings: Number(values.openings),
        //     payRate: {
        //         type: payRateType,
        //         ...(["salary", "hourly", "commission"].includes(payRateType) &&
        //             commission !== "" &&
        //             commission !== undefined
        //             ? {
        //                 commission: commission,
        //             }
        //             : {
        //                 commission: null,
        //             }),
        //         ...(payRateType === "unpaid_internship" &&
        //             unpaidInternship !== "" &&
        //             unpaidInternship !== undefined
        //             ? {
        //                 unpaidInternship: unpaidInternship,
        //             }
        //             : {
        //                 unpaidInternship: null,
        //             }),
        //         ...(payRateType === "salary" &&
        //             salaryMin !== "" &&
        //             salaryMax !== "" &&
        //             salaryMin !== undefined &&
        //             salaryMax !== undefined
        //             ? {
        //                 salary: {
        //                     min: Number(salaryMin),
        //                     max: Number(salaryMax),
        //                 },
        //             }
        //             : {
        //                 salary: null,
        //             }),
        //         ...(payRateType === "hourly" &&
        //             hourlyMin !== "" &&
        //             hourlyMax !== "" &&
        //             hourlyMin !== undefined &&
        //             hourlyMax !== undefined
        //             ? {
        //                 hourly: {
        //                     min: Number(hourlyMin),
        //                     max: Number(hourlyMax),
        //                 },
        //             }
        //             : {
        //                 hourly: null,
        //             }),
        //     },
        // };

        // await updateEntryForJobDetailsNewCollection(
        //     jobId,
        //     jobDetailsNewCollectionData,
        // )
        //     .then((data) => {
        //         toast.success("Job (New) updated successfully");
        //     })
        //     .catch(() => {
        //         toast.error("There was an error updating Job (New)");
        //     });
    };

    const setIntialValuesToForm = (jobData: any) => {
        if (!jobData || (jobData && !Object.keys(jobData).length)) return;
        setValue("payRateType", jobData?.payRate?.type ?? "");
        setValue("jobName", jobData?.jobName ?? "");
        // setValue("solutionType", job?.solutionType ?? "");
        // setValue("startDate", job?.startDate?.toDate() ?? "");
        setValue("openings", empData?.currentPlan ==="annual"?jobData?.openings ?? "":1);
        setValue("applicationFlow", jobData?.applicationFlow ?? "");
        setValue("workSettings", jobData?.workSettings ?? "");
        setValue("workTerm", jobData?.workTerm ?? "");
        setValue("workType", jobData?.workType ?? "");
        setValue("hoursPerWeekMin", jobData?.hoursPerWeek?.min ?? "");
        setValue("hoursPerWeekMax", jobData?.hoursPerWeek?.max ?? "");
        setValue("aspectsOfJob", jobData?.jobDescription?.aspectsOfJob ?? []);
        setValue("jobSummary", jobData?.jobDescription?.jobSummary ?? "");
        setValue("whatsInItForYou", jobData?.jobDescription?.whatsInItForYou ?? "");
        setValue("dayInLife", jobData?.jobDescription?.dayInLife ?? "");

        if (
            jobData?.jobDescription?.dayInLife &&
            jobData?.jobDescription?.dayInLife.length
        ) {
            setValue("jobSummary", jobData?.jobDescription?.dayInLife ?? "");
        }

        setValue("mustHaves", jobData?.jobDescription?.mustHaves ?? "");

        setTimeout(() => {
            if (jobData?.payRate?.type === "salary") {
                setValue("salaryMin", String(jobData?.payRate?.salary?.min) ?? "");
                setValue("salaryMax", String(jobData?.payRate?.salary?.max) ?? "");
                setValue("commission", jobData?.payRate?.commission ?? null);
            } else if (jobData?.payRate?.type === "hourly") {
                setValue("hourlyMin", String(jobData?.payRate?.hourly?.min) ?? "");
                setValue("hourlyMax", String(jobData?.payRate?.hourly?.max) ?? "");
                setValue("commission", jobData?.payRate?.commission ?? null);
            } else if (jobData?.payRate?.type === "commission") {
                setValue("commission", jobData?.payRate?.commission ?? "");
            } else if (jobData?.payRate?.type === "unpaid_internship") {
                setValue("unpaidInternship", jobData?.payRate?.unpaidInternship ?? "");
            }

            setTimeout(() => {
                setValue(
                    "isCommission",
                    jobData?.payRate?.commission?.length ? true : false,
                );

                setTimeout(() => {
                    if (jobData?.payRate?.type === "salary") {
                        setValue("commission", jobData?.payRate?.commission ?? null);
                    } else if (jobData?.payRate?.type === "hourly") {
                        setValue("commission", jobData?.payRate?.commission ?? null);
                    }

                    setValue("aspectsOfJob", jobData?.jobDescription?.aspectsOfJob ?? []);

                    setValue("aboutThisJob", jobData?.jobDescription?.aboutThisJob ?? []);

                    setValue(
                        "mustHavesCheckboxes",
                        jobData?.jobDescription?.mustHavesCheckboxes ?? [],
                    );

                    if (jobData?.jobDescription?.aspectsOfJobOther?.length) {
                        setValue("isAspectsOfJobOther", true);

                        setTimeout(() => {
                            setValue(
                                "aspectsOfJobOther",
                                jobData?.jobDescription?.aspectsOfJobOther ?? "",
                            );
                        }, 100);
                    } else {
                        setValue("isAspectsOfJobOther", false);
                    }
                }, 100);
            }, 500);
        }, 1000);
    };

    const handleAddAboutThisJob = (
        code: string,
        labelLeft: string,
        labelRight: string,
        sliderValue: string,
        remove: boolean,
    ) => {

    };

    useEffect(() => {
        if (job && !isInitialized) {
            setIntialValuesToForm(job);
            setIsInitialized(true);
        }
    }, [job, isInitialized]);


    useEffect(() => {
        if (isSubmittingJobFirstTime) {
            const newSchema = getJobsNewSchema_emp(isSubmittingJobFirstTime);
            setFormSchema(newSchema);
            setIntialValuesToForm(job);
        } else {
            const newSchema = getJobsNewSchema_emp(false);
            setFormSchema(newSchema);
        }
    }, [isSubmittingJobFirstTime, reset]);

    // if (!job) return <></>;

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-[60vw] flex flex-col justify-center items-center ">
                    <div className="w-[50vw] flex justify-center  ">
                        <div className="grid w-[50vw] gap-1 px-10 grid-cols-1 md:grid-cols-1 mb-4 ">


                            <Controller
                                control={control}
                                name="jobName"
                                render={(field) => (

                                    <FloatingLabelInput
                                        {...field}
                                        display="Job Title"
                                        placeholder="Enter Job Title"
                                        readOnly={isFieldLocked}
                                        error={errors?.jobName?.message}
                                        required
                                    />
                                )}
                            />
                            {empData?.currentPlan ==="annual"&&<div className="flex w-[46.1vw] justify-center items-center">
                                <Controller
                                    control={control}
                                    name="openings"
                                    render={(field) => {
                                        const { value, onChange, ...restField } = field;

                                        return (
                                            <div className="flex  w-[47vw]">
                                                <FloatingLabelInput
                                                    {...restField}
                                                    value={value === "" ? "" : Number(value)}
                                                    onChange={(e: any) => {
                                                        if (isNaN(Number(e.target.value))) {
                                                            return;
                                                        }

                                                        const valueAsNumber =
                                                            e.target.value === "" ? "" : Number(e.target.value);

                                                        onChange(valueAsNumber);
                                                    }}
                                                    display="Openings"
                                                    placeholder="Enter Openings"
                                                    readOnly={isFieldLocked}
                                                    error={errors?.openings?.message}
                                                    required
                                                />
                                                <div className=" mt-10">
                                                    <InfoIcon
                                                        tooltipText={"The number of openings  is also sometimes known as the number of  \"seats\"  for a given requisition  "}
                                                    />
                                                </div>

                                            </div>
                                        );
                                    }}
                                />


                            </div>}



                        </div>
                    </div>
                </div>

                <div className=" w-full mt-44 flex flex-row justify-between ">
                    <button
                        type="button"
                        disabled={isSubmitting}
                        className="h-[50px] w-[210px] rounded-[70px] border-[1px] border-[#15415E] px-5 py-3 bg-[#15415E] hover:border-[#1d5174] text-white text-[16px] leading-[19.6px] font-[400]"
                        onClick={() => { reset(); navigate("/jobs") }}
                    >
                        Cancel
                    </button>

                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="h-[50px] w-[210px] rounded-[70px] bg-[#15415E] px-5 py-3  hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
                    >
                        {isSubmitting ? "Saving..." : "Next"}
                    </button>
                </div>
            </form>
            <ToastAlert />
        </>
    );
}

export default AddJobStep1;
