import { useState, useEffect } from "react";
import CompanyProfileCard from "../CompanyProfile/CompanyProfileCardWrapper";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import toast from "react-hot-toast";
import { getBillingContactSchema } from "../../../../../../schemas/getBillingContactSchema";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import Input from "../../../../../custom-ui/new-ui/Input";
import { EmployerNew } from "../../../../../../dataTypes/Employer";
import {
  updateEmployerNew,
  useEmployer,
} from "../../../../../../data/employer";
import ToastAlert from "../../../../../custom-ui/toast-alert/ToastAlert";
import { useAuth } from "../../../../../../providers/auth";

type BillingContactCardProps = {};

const BillingContactCard = ({}: BillingContactCardProps) => {
  const { user } = useAuth();
  const employerId = user?.claims?.employerId;
  const { data: employer } = useEmployer(employerId ?? "");

  // @ts-ignore
  const [isInitialized, setIsInitialized] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formSchema] = useState(getBillingContactSchema());

  type FormValues = z.infer<ReturnType<typeof getBillingContactSchema>>;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: employer?.billingContact?.email ?? "",
      name: employer?.billingContact?.name ?? "",
      phone: employer?.billingContact?.phone ?? "",
      title: employer?.billingContact?.title ?? "",
    },
    reValidateMode: "onBlur",
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    trigger,
    watch,
    formState: { errors, isSubmitting },
  } = form;

  const onSubmit = async ({ ...values }: FormValues) => {
    const { email, name, phone, title, ...restValues } = values;
    console.log(values, "=======>values");

    const employerCollectionData: EmployerNew = {
      billingContact: {
        email: email,
        name: name,
        title: title,
        phone: phone,
      },
    };

    console.log(employerCollectionData, "<--------emp profile");

    await updateEmployerNew(employerId, employerCollectionData)
      .then(() => {
        toast.success("Billing Contact updated successfully");
        setIsEditing(false);
      })
      .catch(() => {
        toast.error("There was an error updating Billing Contact");
      });
  };

  const handleButtonEdit = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    reset();
    setIntialValuesToForm(employer);
    setIsEditing(false);
  };

  const setIntialValuesToForm = (employerData: any) => {
    if (!employerData || (employerData && !Object.keys(employerData).length))
      return;

    setValue("email", employer?.billingContact?.email ?? "");
    setValue("name", employer?.billingContact?.name ?? "");
    setValue("phone", employer?.billingContact?.phone ?? "");
    setValue("title", employer?.billingContact?.title ?? "");
  };

  useEffect(() => {
    if (employer && !isInitialized) {
      setIntialValuesToForm(employer);
      setIsInitialized(true);
    }
  }, [employer, isInitialized]);

  return (
    <>
      <CompanyProfileCard
        title="Billing Contact"
        onButtonClick={isEditing ? handleSubmit(onSubmit) : handleButtonEdit}
        onCancelClick={handleCancelClick}
        isEditing={isEditing}
        isSubmitting={isSubmitting}
        className="h-fit h-auto"
      >
        <div className="flex flex-col gap-[30px]">
          <form className="mt-[30px]">
            <div className="flex flex-col gap-[30px]">
              <Controller
                control={control}
                name="email"
                render={(field) => (
                  <Input
                    {...field}
                    display="Email"
                    placeholder="Enter Email"
                    readOnly={!isEditing}
                    error={errors?.email?.message}
                    required={isEditing}
                  />
                )}
              />

              <Controller
                control={control}
                name="name"
                render={(field) => (
                  <Input
                    {...field}
                    display="Full Name"
                    placeholder="Enter Full Name"
                    readOnly={!isEditing}
                    error={errors?.name?.message}
                    required={isEditing}
                  />
                )}
              />

              <Controller
                control={control}
                name="title"
                render={(field) => (
                  <Input
                    {...field}
                    display="Designation"
                    placeholder="Enter Designation"
                    readOnly={!isEditing}
                    error={errors?.title?.message}
                    required={isEditing}
                  />
                )}
              />

              <div className="relative mb-7">
                <PhoneInputWithCountry
                  className="peer mt-1 block w-full px-3 py-2 inp sm:text-sm border-none"
                  style={{ boxShadow: "none" }}
                  name="phone"
                  international
                  defaultCountry="US"
                  countryCallingCodeEditable={false}
                  control={control}
                  readOnly={!isEditing}
                  disabled={!isEditing}
                  //onChange={async () => await trigger("phone")}
                  onBlur={async () => await trigger("phone")}
                  rules={{
                    required: "Phone number is required",
                    pattern: {
                      value: /^\+?[1-9]\d{1,14}$/,
                      message: "Invalid phone number",
                    },
                  }}
                />
                <label
                  htmlFor="phone"
                  className="absolute -top-4 text-sm text-[#15415E] bg-white px-1 transition-all duration-200 peer-placeholder-shown:top-2 peer-placeholder-shown:text-[#15415E] peer-placeholder-shown:text-sm peer-focus:-top-4 peer-focus:text-[#15415E] peer-focus:text-sm"
                >
                  Phone Number <span className="mr-1 text-red-600">*</span>
                </label>
                {errors.phone && (
                  <p className="mt-1 text-sm text-left text-red-600">
                    {errors.phone.message}
                  </p>
                )}
              </div>
            </div>
          </form>
        </div>
      </CompanyProfileCard>

      <ToastAlert />
    </>
  );
};

export default BillingContactCard;
