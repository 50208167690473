import { useEffect, useState } from "react";
import { cn } from "../../../../../../util/cn";
import { useAuth } from "../../../../../../providers/auth";
import CloudTickSVG from "../../../../../custom-ui/icons/CloudTickSVG";
import { getUser } from "../../../../../../providers/database";

type TSpeakWithAccountExecutiveThankYouModalProps = {
  onDone: () => void;
};

const SpeakWithAccountExecutiveThankYouModal = ({
  onDone,
}: TSpeakWithAccountExecutiveThankYouModalProps) => {
  const { user } = useAuth();
  const [userData, setUserData] = useState(null);

  const onSubmit = () => {
    onDone();
  };

  useEffect(() => {
    getUser(user.id).then((userDataObj) => {
      setUserData(userDataObj);
    });
  }, []);

  return (
    <>
      <div
        className={cn(
          "flex flex-col items-center overflow-hidden mt-[20px]",
          {},
        )}
      >
        <CloudTickSVG />
        <div className="flex flex-col gap-[15px] items-center mt-[30px]">
          <p className="w-full font-[Gilroy-Bold] font-[400] text-[30px] leading-[37.14px] text-[#15415E] text-center">
            Thank You for Contacting Us!
          </p>

          <p className="w-[412px] font-[Gilroy-Medium] font-[400] text-[20px] leading-[27px] text-[#616161] text-center">
            We have received your message.
            <br />
            We’ll reach you out on following details..
          </p>
        </div>

        <div className="mt-[30px] w-[580px] min-h-[168px] h-auto rounded-[20px] bg-[#E8F6FE] py-[30px] px-[43px] flex flex-col text-center text-[#000000] text-[20px] leading-[36px] font-[500] font-[Gilroy-Medium]">
          <p>Name: {userData?.name}</p>
          <p>Email Address: {userData?.email}</p>
          <p>Phone Number: {userData?.phone}</p>
        </div>

        <div className="flex flex-row justify-center items-center mt-[35px]">
          <button
            className="flex justify-center items-center h-[60px] w-[260px] h-[60px] py-[20px] px-[70px] rounded-[70px] bg-[#183852] text-[#FFFFFF] text-[20px] leading-[24.76px] font-[400] font-[Gilroy-Bold]"
            onClick={onSubmit}
          >
            OK
          </button>
        </div>
      </div>
    </>
  );
};

export default SpeakWithAccountExecutiveThankYouModal;
