import { forwardRef } from "react";
import { cn } from "../../../../../util/cn";

interface InputProps {
  name: string;
  display: string;
  placeholder?: string;
  readOnly?: boolean;
  maxLength?: number;
  required?: boolean;
  error?: string;
  touched?: boolean;
  [key: string]: any; 

}

const FloatingLabelInput = forwardRef(
  (
    {
      name,
      display,
      placeholder = " ",
      readOnly = false,
      maxLength,
      required = false,
      error,
      touched,

      ...props
    }: InputProps,
    ref: any,
  ) => {
    return (
      <div id={name} className="relative mb-6 ml-[-10px] w-full">
        {/* Input Field */}
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          maxLength={maxLength}
          ref={ref} // forwarding the ref
          className={cn(
            `peer w-full  border-0 border-b-2 border-gray-400 
            focus:outline-none focus:ring-0 focus:border-gray-400
            text-instant-teams-deal-Main h-16 text-base shadow-none items-center  pt-5 pb-1
            placeholder-transparent
            ${readOnly ? "cursor-not-allowed" : ""}
            `,
          )}
          {...props}
        />
       
        {/* Floating Label */}
        <label
          htmlFor={name}
          className={cn(
            `absolute left-3 text-instant-teams-blue-Main text-[1rem] transition-all duration-200 
            peer-placeholder-shown:top-4 peer-placeholder-shown:text-[1rem] 
            peer-placeholder-shown:text-instant-teams-deal-Main peer-placeholder-shown:left-3 
            peer-focus:top-0 peer-focus:text-[0.9rem] peer-focus:text-instant-teams-deal-Main
            `,
          )}
        >
          {display}{" "}
          {required &&display&& <span className="text-red-500 text-base">*</span>}
        </label>
       <div className="border-b-2 border-gray-300 ml-3   "></div>
        {error && (
          <p className="text-[1rem] font-medium text-[red] mt-1 ">{error}</p>
        )}
      </div>
    );
  },
);

FloatingLabelInput.displayName = "FloatingLabelInput";

export default FloatingLabelInput;
