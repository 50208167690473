import { useState } from "react";
import { useEmployer } from "../../../../../data/employer";
import { useAuth } from "../../../../../providers/auth";
import DialogWrapper from "../../../../custom-ui/dialog/Dialog_NewUI";
import Meta from "../../../../custom-ui/meta/Meta";
import BillingContactCard from "../Component/BillingInformation/BillingContactCard";
import SavedCardComponent from "../Component/BillingInformation/SavedCardComponent";
import ElementsProvider from "../Component/CurrentPlan/ElementsProvider";

type Props = {};

const BillingInformation_NewUI = (props: Props) => {
  const { user } = useAuth();
  const employerId = user?.claims?.employerId;
  const { data: employer } = useEmployer(employerId ?? "");
  const [isModalRetryPayment, setIsModalRetryPayment] = useState(false);

  const handleRetryPayment = () => {
    setIsModalRetryPayment(true);
  };

  const handleCloseRetryPayment = () => {
    setIsModalRetryPayment(false);
  };

  return (
    <>
      <Meta title="Billing Information" />
      <div className="flex flex-col">
        <p className="text-[#15415E] font-[Gilroy-Bold] font-[400] text-[32px] leading-[39.62px]">
          Billing Information
        </p>
        {/* {employer?.billingContact} */}
        {!employer?.billingContact && <div className="mt-2 py-4 rounded-lg px-4 w-full opacity-80 bg-[#f5bab3] ">
          <p className="text-red-600 font-[500]">Please add your billing information.</p>
        </div>}
        {((employer?.stripeData?.subscriptionId &&
          employer?.stripeData?.subscriptionStatus === "past_due") ||
          (!employer?.stripeData?.subscriptionId &&
            employer?.stripeData?.subscriptionStatus === "canceled" &&
            employer?.stripeData?.reason !== "cancellation_requested")) && (
          <div
            className="w-full min-h-[60px] h-auto bg-[#FFD2D2] mt-[30px] -mb-[25px] rounded-[10px] p-[20px] text-[#E02F2F] font-[Gilroy-Medium] font-[400] text-[20px] leading-[24.26px] flex flex-row justify-between items-center gap-[10px]"
            style={{ boxShadow: "0px 0px 34px 0px #1E596D0A" }}
          >
            <p>
              Your subscription is currently paused due to a payment issue.
              Please update your card Information and retry payment.
            </p>

            <button
              className="font-[Gilroy-SemiBold] font-[400] text-[16px] leading-[19.6px] bg-[#15415E] text-[#FFFFFF] w-[150px] min-h-[40px] h-auto rounded-[70px] px-[21px] py-[10px] whitespace-nowrap"
              onClick={handleRetryPayment}
            >
              Retry Payment
            </button>
          </div>
        )}

        <div className="flex flex-row lg:flex-col md:flex-col mt-[35px] gap-[30px]">
          <BillingContactCard />
          {employer?.currentPlan === "monthly" ||
          (employer?.stripeData?.subscriptionId === null &&
            employer?.stripeData?.subscriptionStatus === "canceled") ? (
            <SavedCardComponent />
          ) : (
            <></>
          )}
        </div>
      </div>

      {isModalRetryPayment && (
        <DialogWrapper
          title="Retry Payment"
          onClose={handleCloseRetryPayment}
          displayCloseIcon={true}
          size="max-w-[730px]"
        >
          <ElementsProvider
            employerId={employer.id}
            selectedSubscription="monthly"
          />
        </DialogWrapper>
      )}
    </>
  );
};

export default BillingInformation_NewUI;
